import React from "react"
 
function Safety(props) {
  return (
    <section>

    
    <div className="container simple-page">
      <h1>Safety</h1>

<p>While this is fun to meet people around the world while traveling. As the community of our mobile platform will expand, user safety is our priority. It is important to remain safe following these guidelines will help (but not prevent) minimize the risks of incidents. Please be sure to follow these guidelines online and offline. </p>

<h2 className="banner title center">While being Online</h2>

<h2>No personal information</h2>
Never give away any personal information such as full name, home/work address, date of birth, social security number, credit/debit card information or bank information. Also we will never send an email to enter your username, password or credit/debit card information such communications should be reported immediately. 

<h2>No financial information</h2>
ToMeetApp is not a platform to conduct any sort of transactions, therefore you should not provide any sort of financial information nor respond to any sort of request. Please report immediately suspicious behavior. 

<h2>Matched user </h2>
You have the ability to delete or report any matched user and it is recommended to report concerns about suspicious users before deleting them so we can monitor the situation. Note: swipe left on the matched user to have access to these options.

<h2>Report any suspicious behavior</h2>
You have the ability to report anyone who violate our <a href="/legal/terms-of-use.html">terms of use</a>



<h2 className="banner title center">While being Offline</h2>

<h2>Meet and stay in public</h2>
Always meet in a populated, public place. Avoid private or remote locations such as home or apartment. Don’t feel pressured, it is recommend to leave immediately in that situation.

<h2>Inform friends and family about your plans</h2>
Keep anyone you can about your current plans while meeting with someone and share with them his/her name along with the location of meeting and time. Also ensure your cell phone is fully charged.

<h2>Arrange your own transportation</h2>
Never let anyone arrange your transportation, no Uber you haven’t ordered, no pick up/drop off. It is important that you are in control of the transportation at all times.

<h2>No alcoholic/drug use</h2>
It is not encourage to be under the influence of alcohol or drugs  as it can impair your judgement and could potentially become dangerous. Always stay with your drinks never leave sight of it so no substance gets added. 

<h2>Maintain healthy status</h2>

<p><b>Protect yourself:</b> in case of intercourse it is your responsibility to make sure you and your partner use proper protection such as condoms or other mechanism to reduce the risk of a health issue.</p>
<p><b>Vaccination and blood test:</b> Make sure you assess your health and be honest about it with your partner in case of intercourse. Also before traveling or being in contact with people make sure you have your vaccinations up to date to minimalist the risk of being infected with disease that could have been prevented.</p>

<h2>Report immediately and call 911:</h2>
<ul>
<li>Rape</li>
<li>Health issues</li> 
<li>Person in danger</li>
<li>Physical or sexual violence</li>
<li>Abuse</li>
<li>Domestic violence</li>
<li>Suspicious behavior</li>
</ul>

    </div>
    </section>
  )
}
 
export default Safety;
import React from "react";
import './intro.scss';
 
function Intro(props) {
    let {eyebrow, title, content, subTitle} = props;

  return (
    <div className="intro">
        { eyebrow && <span className="eyebrow">{eyebrow}</span> }
        { title && <h1>{title}</h1> }
        { subTitle && <h2>{subTitle}</h2> }
        { content && <p>{content}</p> } 
    </div>
  )
}
 
export default Intro;
import React, {useState, useRef, useEffect} from "react";
import { Link } from 'react-router-dom';
import useAnalyticsEventTracker from './../helpers/useAnalyticsEventTracker';
import useAnalyticsScrollEvent from './../helpers/useAnalyticsScrollEvent';

function Careers(props) {
    const gaEventTracker = useAnalyticsEventTracker('Careers');
    useAnalyticsScrollEvent('Careers');
    return (
        <>
            <section className="bg-white top-margin">
                <div className="container small">
                    <div className="intro-container center">
                    <div className="title-container">
                        <div className="eyebrow">Careers</div>
                        <h2 className="title-2">Let's create opportunities together</h2>
                        {/* <h2 className="">Let's create opportunities together</h2> */}
                    </div>
                    </div>
                </div>
                <div className="container row">
                    <div className="col-xs-12 col-sm-4">
                    <div className="career-card-container">
                        <h5>Frontend engineer</h5>
                        <div className="location">Salt Lake City, UT</div>
                        <h6>Role</h6>
                        <div className="content">
                        We are looking for an enthusiastic, experienced Frontend Engineer to join the Meetapp product team. 
                        You will work closely with our product, design, and backend teams to ensure the delivery of high-quality, 
                        robust, and scalable solutions. Your expertise in React Native will be vital in creating an exceptional user experience on our platform.
                        </div>
                        <h6>Responsabilities</h6>
                        <div className="content">
                        <ul>
                            <li>Expert developing, implementing, user-facing features for JavaScript-based web and mobile applications using the React ecosystem</li>
                            <li>Build, and maintain high performance, reusable, and reliable code</li>
                            <li>Understanding of database design, performance, and noSQL</li>
                            <li>Experience working with hosted web applications/SaaS</li>
                            <li>Ability to work collaboratively in a team and participate in feature design discussions</li>
                        </ul>
                        </div>
                        <Link onClick={()=>{gaEventTracker('Frontend engineer - Navigate to Contact Sales','Button')}} className="cta" to="/contact-sales">Join us</Link>
                    </div>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                    <div className="career-card-container">
                        <h5>Backend engineer</h5>
                        <div className="location">Salt Lake City, UT</div>
                        <h6>Role</h6>
                        <div className="content">
                        We are looking Backend Engineer that will be pivotal in shaping the backbone of our application and ensuring that it is secure, scalable, and robust. 
                        This role requires strong collaboration with our frontend team, data scientists to align on the development and maintenance of the platform.
                        </div>
                        <h6>Responsabilities</h6>
                        <div className="content">
                        <ul>
                            <li>Expert developing, implementing, robust and scalable server-side applications with Firebase and Google Cloud Platform (GCP)</li>
                            <li>Understanding of Kubernetes and Kafka for automating deployment, scaling, and management of application containers</li>
                            <li>Ensure the implementation of secure procedures using Okta Single Sign-On (SSO) and encryption technologies</li>
                            <li>Maintain the central database, ensure high performance, and respond to requests from the frontend</li>
                            <li>Experience working with hosted web applications/SaaS</li>
                            <li>Ability to work collaboratively in a team and participate in feature design discussions</li>
                        </ul>
                        </div>
                        <Link onClick={()=>{gaEventTracker('Backend engineer - Navigate to Contact Sales','Button')}} className="cta" to="/contact-sales">Join us</Link>
                    </div>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                    <div className="career-card-container">
                        <h5>Data Scientist</h5>
                        <div className="location">Salt Lake City, UT</div>
                        <h6>Role</h6>
                        <div className="content">
                        The ideal candidate for this position would have a strong understanding of machine learning techniques, 
                        conduct data analysis, use ML algorithms to build predictive models based on large amounts of data, excellent problem-solving skills, and a passion for translating data into actionable insights and ensure the data complies with legal regulations.
                        </div>
                        <h6>Responsabilities</h6>
                        <div className="content">
                        <ul>
                            <li>Expert in quantitative analysis, the use of data mining and statistical techniques to understand and predict user behavior, engagement, and retention</li>
                            <li>Build, validate, and implement machine learning models that improve and simplify user experiences, and drive key business results</li>
                            <li>Use GCP and Big Query to store, retrieve, and manipulate data for analysis</li>
                            <li>Work closely with the engineering team to integrate your solutions into our mobile app seamlessly and efficiently</li>
                            <li>Maintain data quality and integrity: Work to eliminate redundant and irrelevant data, and create systems for ongoing data cleanup</li>
                        </ul>
                        </div>
                        <Link onClick={()=>{gaEventTracker('Data Scientist - Navigate to Contact Sales','Button')}} className="cta" to="/contact-sales">Join us</Link>
                    </div>
                    </div>
                </div>
                <div className="container row">
                    <div className="col-xs-12 col-sm-4">
                    <div className="career-card-container">
                        <h5>Organizational Psychologist</h5>
                        <div className="location">Salt Lake City, UT</div>
                        <h6>Role</h6>
                        <div className="content">
                        As an Organizational Psychologist at our organization, you will play an essential role in developing and enhancing our AI and ML powered platform focusing on solving employee retention challenges.
                        By combining your knowledge of human behavior and organizational structures with our data-driven platform, you will help optimize workplace environments, boost employee morale, and increase overall productivity and retention rates.
                        </div>
                        <h6>Responsabilities</h6>
                        <div className="content">
                        <ul>
                            <li>Conduct extensive research on factors affecting employee retention, including motivation, work environment and team dynamics</li>
                            <li>Work closely with our data science team to provide psychological insights that guide the development of our AI and ML models, ensuring they are finely tuned to address the complexities of human behavior and organizational culture.</li>
                            <li>Develop and implement strategies and interventions to improve employee engagement, based on data-driven insights gleaned from our platform</li>
                            <li>Evaluate the effectiveness of organization structures and systems, and propose necessary changes</li>
                            <li>Use your psychological expertise to help improve the platform's user experience, ensuring it caters effectively to the emotional and cognitive needs of its users</li>
                        </ul>
                        </div>
                        <Link onClick={()=>{gaEventTracker('Organizational Psychologist - Navigate to Contact Sales','Button')}} className="cta" to="/contact-sales">Join us</Link>
                    </div>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                    <div className="career-card-container">
                        <h5>Marketing and Sales Director</h5>
                        <div className="location">Salt Lake City, UT</div>
                        <h6>Role</h6>
                        <div className="content">
                        As the Marketing and Sales Director you will spearhead our efforts to position our platform effectively in the marketplace, expand our customer base, and drive revenue growth.
                        The ideal candidate for this position would have a strong track record in both marketing and sales, a deep understanding of B2B software marketing and sales, a passion for technology, and exceptional leadership skills.
                        </div>
                        <h6>Responsabilities</h6>
                        <div className="content">
                        <ul>
                            <li>Develop and implement a comprehensive marketing strategy that enhances brand awareness, positions our product effectively, and drives market growth</li>
                            <li>Develop and manage our sales strategy, with a focus on identifying target customers, understanding their needs, and aligning our sales approach to meet those needs</li>
                            <li>Work closely with product and engineering teams to understand the unique benefits of our AI and ML technology and articulate these advantages in our marketing and sales messaging</li>
                            <li>Continually analyze market trends and competitor strategies to inform our marketing and sales strategies and ensure we remain competitive</li>
                            <li>Develop and maintain strong relationships with key customers, industry influencers, and strategic partners</li>
                            <li>Drive the revenue growth of our platform by maximizing lead generation, conversion rates, and customer retention</li>
                        </ul>
                        </div>
                        <Link onClick={()=>{gaEventTracker('Marketing and Sales Director - Navigate to Contact Sales','Button')}} className="cta" to="/contact-sales">Join us</Link>
                    </div>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                    <div className="career-card-container investor">
                        <h5>Investor Relations</h5>
                        <div className="content">
                        Meetapp a pioneering venture in the employee sector. We have dedicated ourselves to improve employee happiness, and we're thrilled with the progress we've made so far.
                        </div>
                        <h6>Why Invest?</h6>
                        <div className="content">
                        We have developed an innovative solution that directly addresses employee rentention. This unique approach gives us a competitive edge in the marketplace and positions us for rapid growth.
                        </div>
                        <h6>The Opportunity</h6>
                        <div className="content">
                        Now, we are at a critical juncture in our journey. To propel our growth, we are seeking the support of like-minded investors and stakeholders who share their passion for cultivating a work culture where employees feel valued and secure.
                        <br/><br/>The funds will be strategically channeled into research, development, and implementation of groundbreaking solutions, ultimately fueling the dreams of countless organizations and their workforce.
                        </div>
                        <Link onClick={()=>{gaEventTracker('Investor Relations - Navigate to Contact Sales','Button')}} className="cta" to="/contact-sales">Contact us</Link>
                    </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Careers;
import React from "react"
 
function Disclaimer(props) {
  return (
    <section>

    
    <div className="container simple-page">
      <h1>Disclaimer</h1>

            <p>This disclaimer ("Disclaimer", "Agreement") is an agreement between Mobile Application Developer ("Mobile Application Developer", "us", "we" or "our") and you ("User", "you" or "your"). This Disclaimer sets forth the general guidelines, terms and conditions of your use of the To Meet App mobile application and any of its products or services (collectively, "Mobile Application" or "Services").</p>

            <h2>Representation</h2>

            <p>Any views or opinions represented in this Mobile Application are personal and belong solely to Mobile Application Developer and do not represent those of people, institutions or organizations that the owner may or may not be associated with in professional or personal capacity unless explicitly stated. Any views or opinions are not intended to malign any religion, ethnic group, club, organization, company, or individual.</p>

            <h2>Content and postings</h2>

            <p>You may not modify, print or copy any part of the Mobile Application. Inclusion of any part of this Mobile Application in another work, whether in printed or electronic or another form or inclusion of any part of the Mobile Application in another mobile application by embedding, framing or otherwise without the express permission of Mobile Application Developer is prohibited. </p>

            <p>You may submit content in the Mobile Application. By uploading or otherwise making available any information to Mobile Application Developer, you grant Mobile Application Developer the unlimited, perpetual right to distribute, display, publish, reproduce, reuse and copy the information contained therein. You may not impersonate any other person through the Mobile Application. You may not post content that is defamatory, fraudulent, obscene, threatening, invasive of another person's privacy rights or that is otherwise unlawful. You may not post content that infringes on the intellectual property rights of any other person or entity. You may not post any content that includes any computer virus or other code designed to disrupt, damage, or limit the functioning of any computer software or hardware.</p>
                <h2>Compensation</h2>

            <p>Some of the links in the Mobile Application may be "affiliate links". This means if you click on the link and purchase an item, Mobile Application Developer will receive an affiliate commission.</p>
                <h2>Indemnification and warranties</h2>

            <p>While we have made every attempt to ensure that the information contained in the Mobile Application is correct, Mobile Application Developer is not responsible for any errors or omissions, or for the results obtained from the use of this information. All information in the Mobile Application is provided "as is", with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied. In no event will Mobile Application Developer be liable to you or anyone else for any decision made or action taken in reliance on the information in the Mobile Application or for any consequential, special or similar damages, even if advised of the possibility of such damages. Information in the Mobile Application is for general information purposes only and is not intended to provide legal, financial, medical, or any other type of professional advice. Please seek professional assistance should you require it. Furthermore, information contained in the Mobile Application and any pages linked to from it are subject to change at any time and without warning.</p>

            <p>We reserve the right to modify this Disclaimer at any time, effective upon posting of an updated version of this Disclaimer in the Mobile Application. When we do we will revise the updated date at the bottom of this page. 
              Continued use of the Mobile Application after any such changes shall constitute your consent to such changes. Policy was created with <a href="https://www.websitepolicies.com/disclaimer-generator">WebsitePolicies.com</a></p>

            <h2>Acceptance of this disclaimer</h2>

            <p>You acknowledge that you have read this Disclaimer and agree to all its terms and conditions. By accessing the Mobile Application you agree to be bound by this Disclaimer. If you do not agree to abide by the terms of this Disclaimer, you are not authorized to use or access the Mobile Application.</p>

            <h2>Contacting us</h2>

            <p>If you have any questions about this Disclaimer, please contact us.</p>

            <p>This document was last updated on November 6, 2018</p>
    </div>
    </section>
  )
}
 
export default Disclaimer;

import React from "react";
import './tab.scss';
import useAnalyticsEventTracker from './../../helpers/useAnalyticsEventTracker';

class Tabs extends React.Component{
    state ={
      activeTab: this.props.children[0].props.label
    }
    changeTab = (tab) => {
  
      this.setState({ activeTab: tab });
    };
    render(){
      
      let content;
      let buttons = [];
      return (
        <div>
          {React.Children.map(this.props.children, child =>{
            buttons.push(child.props.label)
            if (child.props.label === this.state.activeTab) content = child.props.children
          })}
           
          <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab}/>
          <div className="tab-content">{content}</div>
          
        </div>
      );
    }
  }
  
  const TabButtons = ({buttons, changeTab, activeTab, props}) =>{
    const gaEventTracker = useAnalyticsEventTracker('Why Meetapp Tabs');
    return(
      <div className="tab-buttons">
      {buttons.map((button, i) =>{
         return <span key={i} className={`button ${button === activeTab? 'active': ''}`} onClick={()=>{changeTab(button); gaEventTracker(`Tab: ${button}`,'Tab Click')}}>{button}</span>
      })}
       <div className={`switch_bg ${buttons[0] !== activeTab? 'right': ''}`}></div>
      </div>
    )
  }
  
  const Tab = props =>{
    return(
      <React.Fragment>
        {props.children}
      </React.Fragment>
    )
  }

export {Tabs, Tab}

import React, {useEffect} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; 

import Home from './pages/Home';
import Blog from './pages/Blog';
import BlogArticle from './pages/BlogArticle';
import About from './pages/About';
import WhyMeetapp from './pages/WhyMeetapp';
import EmployeeRetention from './pages/EmployeeRetention';
import Solutions from './pages/Solutions';
import Faqs from './pages/Faqs';
import ContactSales from './pages/ContactSales';
import Community from './pages/Community';
import Enterprise from './pages/Enterprise';
import Platform from './pages/Platform';
import Licences from './pages/Licences';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiePolicy from './pages/CookiePolicy';
import TermsConditions from './pages/TermsConditions';
import TermsUse from './pages/TermsUse';
import Safety from './pages/Safety';
import Disclaimer from './pages/Disclaimer';
import Nav from './components/nav/Nav';
import Footer from './components/footer/Footer';
import NotFoundPage from './pages/NotFoundPage';
import ScrollToTop from "./helpers/ScrollToTop";
import Careers from "./pages/Careers";
import './css/app.scss';
//https://www.freecodecamp.org/news/performance-and-user-tracking-in-react-with-google-analytics/
import ReactGA from 'react-ga4';
import { clarity } from 'clarity-js';





function App() {

  useEffect(() => {
    ReactGA.initialize("G-GRHWX1TZBJ");
    clarity.start({
        projectId: 'fznjtms7q1',
        upload: 'https://m.clarity.ms/collect',
        track: true,
        content: true
    });  
  },[]);
  
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="App light-theme">
        <Nav/>
        <div className="pages-container">
          <Routes className="page-wrap">

              <Route exact path="/" element={<Home />} />
              <Route exact path="/blog" element={<Blog />} />
              <Route exact path="/blog-article" element={<BlogArticle />} />
              <Route path="/about" element={<About />} />
              <Route path="/solutions" element={<Solutions />} />
              <Route path="/why-meetapp" element={<WhyMeetapp />} />
              <Route path="/employee-retention" element={<EmployeeRetention />} />
              <Route exact path="/faqs" element={<Faqs />} />
              <Route exact path="/contact-sales" element={<ContactSales />} />
              <Route exact path="/community" element={<Community />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms-conditions" element={<TermsConditions />} />
              <Route path="/terms-of-use" element={<TermsUse />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
              <Route path="/safety-tips" element={<Safety />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />
              <Route path="/licences" element={<Licences />} />
              <Route path="/platform" element={<Platform />} />
              <Route path="/enterprise" element={<Enterprise />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="*" element={<NotFoundPage />} />
              
          </Routes>
          

          <Footer/>
        </div>

      </div>
    </BrowserRouter>


    
  );
}

export default App;

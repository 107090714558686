import React, {useEffect, useState} from "react";
import './../css/blog.scss';
import BlogData from './../data/blog.json';
import { Link } from 'react-router-dom';
import useAnalyticsEventTracker from './../helpers/useAnalyticsEventTracker';
import useAnalyticsScrollEvent from './../helpers/useAnalyticsScrollEvent';

function Blog() {
  const gaEventTracker = useAnalyticsEventTracker('Blog');
  useAnalyticsScrollEvent('Blog');
  let {featured, list} = BlogData.blog;

  return (
    <div className="blog-container">

      <section className="white featured">
        <Link to="/blog" onClick={()=> {gaEventTracker('Employee Retention','CTA Link')}} className="container row">
        <div className="col-xs-12 col-sm-5 main-image">
          <img src={require(`./../assets/${featured.image}.jpg`)}/>
        </div>
          <div className="col-xs-12 col-sm-7">
          <div className="intro-container">
              <div className="pill">{featured.badge}</div>
              <div className="title-container">
                <h1>{featured.headline}</h1>
                <p>{featured.description}</p>
              </div>
            </div>
          </div>
        </Link> 
      </section>

      <section className="blog-divider container">
        <div className="section-title">Other resources</div>
        <div className="line"></div>
      </section>
      
      <section className="white no-top-margin">
        {list.map((item, i) => {
            return (
              <Link key={i} className="article-preview container row middle-xs"
                onClick={()=> {gaEventTracker('Blog Article | '+item.headline, 'CTA Link')}}
                to="/blog-article"
                state={item}
              >
                 
                  <div className="col-xs-12 col-sm-4 main-image">
                  <img src={require(`./../assets/${item.image}.jpg`)}/>
                </div>
                  <div className="col-xs-12 col-sm-8">
                  <div className="intro-container">
                      <div className="pill">{item.badge}</div>
                      <div className="title-container">
                        <h2>{item.headline}</h2>
                        <p>{item.description}</p>
                      </div>

                      <div className="author-date">{item.date}</div>

                    </div>
                  </div>

              </Link>
            )
          })
        }
      </section>
      
    </div>
  )
}
 
export default Blog;
import React, {useState, useEffect, useRef} from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import './../css/contactsales.scss';
import Lottie from "lottie-react";
import MessageSent from "./../assets/message-sent.json";
import useAnalyticsEventTracker from './../helpers/useAnalyticsEventTracker';

 
function Contact() {
  const gaEventTracker = useAnalyticsEventTracker('Contact sales');
  const ruleSet = new Object();
  ruleSet.firstName = 'Please let us know your first name';
  ruleSet.lastName = 'Please let us know your last name';
  ruleSet.email = 'Please enter a valid email address';
  ruleSet.company = 'Please let us know where you work';
  ruleSet.userIntention = 'Please let us know how we can help';

  const [formValid, setFormValid] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false)

  let formDataEmpty = (formData && Object.keys(formData).length === 0 && Object.getPrototypeOf(formData) === Object.prototype) ? true : false;
  let formErrorsEmpty = (errors && Object.keys(errors).length === 0 && Object.getPrototypeOf(errors) === Object.prototype) ? true : false;

  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();// run this function from an event handler or an effect to execute scroll 

  

  const minMaxLength = (text, minLength, maxLength) => {
      let result = !text || text.length < minLength;
      if(maxLength)
          result = result || text.length < minLength;
      return result;
  }

  useEffect(() => {

    if(!formDataEmpty && formErrorsEmpty && compareKeys(ruleSet, formData)){
      setFormValid(true)
    }
  }, [errors]);
 
  const validEmail = (text) => {
      const regex = RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      
      return !regex.test(text);
  }


  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const validationRules = (name, value) => {
    let err = errors; 
    switch (name) {
      case 'firstName':
        if (!value || minMaxLength(value, 3)) { 
          err.firstName = ruleSet[name]; 
        }else{ 
            delete err.firstName;
        }
        break;
      case 'lastName':
        if (!value || minMaxLength(value, 3)) { 
          err.lastName = ruleSet[name]; 
        }else{ 
            delete err.lastName;
        }
        break;
      case 'email':
        if (!value || validEmail(value, 3)) { 
         err.email = ruleSet[name]; 
        }else{ 
            delete err.email;
        }
        break;
      case 'company':
        if (!value || minMaxLength(value, 3)) { 
          err.company = ruleSet[name]; 
        }else{ 
            delete err.company;
        }
        break
      case 'userIntention':
        if (!value || minMaxLength(value, 3)) { 
          err.userIntention = ruleSet[name]; 
        }else{ 
            delete err.userIntention;
        }
        break

      default:
        break;
    }
    setErrors({
      ...err
    });
  }

  const validate = (event) => {
    const {name,value} = event.currentTarget;
    validationRules(name, value)
  };

  const compareKeys = (a, b) => {
    var aKeys = Object.keys(a).sort();
    var bKeys = Object.keys(b).sort();
    return JSON.stringify(aKeys) === JSON.stringify(bKeys);
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    gaEventTracker('Form Submit Click','Submit Button');

    let formData_minus_message = Object.entries(formData).filter(([key, _]) => key !== "message").reduce((res, [key, value]) => ({ ...res, [key]: value}), {});
    if (!formDataEmpty && formErrorsEmpty && compareKeys(ruleSet, formData_minus_message) && formValid){
        axios({
          method: "POST",
          // url:"http://localhost:3002/send",
          url:"https://tomeetapp.com/api/",
          withCredentials: false,
          data:  formData
        }).then((response)=>{
          if (response.data.status === 'success') {
            console.log('Message sent');
            gaEventTracker('Form Successfully Submited','Request Response');
            resetForm();
          } else if (response.data.status === 'fail') {
            setErrors(response.data.error);
            console.log("Message failed to send");
            gaEventTracker('Form Submit Failed','Request Response');
          }
        })
    }else {
      gaEventTracker('Form Submit Fields Error','Submit Button');
      Array.from(document.querySelectorAll("input")).forEach(
        input => (validationRules(input.name, input.value))
      );
      Array.from(document.querySelectorAll("select")).forEach(
        input => (validationRules(input.name, input.value))
      );
    }


    if(formDataEmpty && formErrorsEmpty) {
      gaEventTracker('Form Submit Empty Fields Error','Submit Button');
        setErrors(ruleSet)
    }
    
  }
  
  const resetForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      input => (input.value = "")
    );
    Array.from(document.querySelectorAll("select")).forEach(
      select => (select.value = "")
    );
    setFormData({});
    executeScroll();
    setSuccess(true)
  }

  return (
    <section className="white salesform top-margin">
        <div className="container row">
          <div className="salescontent col-xs-12 col-sm-6 col-md-6">
           
              <div className="intro-container">

                <div className="title-container">
                  <h2 className="title-2">We're here to answer your questions</h2>
                </div>
                <div ref={myRef} className="desc-container">
                Contact our sales team to book a demo and discover what can meet do for you
                </div>
                <ul className="checklist">
                  <li>Explore use cases and solutions for your team</li>
                  <li>Improve employee retention and company cost</li>
                  <li>Increase team productivity with a strong culture</li>
                </ul>
              </div>
          </div>
          <div className="salesfields col-xs-12 col-sm-6 col-md-6">
            {!success && 
              <form id="contact-form" onSubmit={handleSubmit} method="POST">
                <div className="row form-group">
                    <div className="col-xs-12 col-sm-6 col-md-6">
                      <label htmlFor="firstName">First Name <span className="red">*</span></label>
                      <input type="text" placeholder="Your first name" className={`form-control ${errors.firstName ? 'error':''}`} name="firstName" id="firstName" onBlur={validate} onChange={handleChange} />
                      {errors.firstName && <p className="error-text">{errors.firstName}</p>}
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6">
                      <label htmlFor="lastName">Last Name <span className="red">*</span></label>
                      <input type="text" placeholder="Your last name" className={`form-control ${errors.lastName ? 'error':''}`} name="lastName" id="lastName" onBlur={validate} onChange={handleChange} />
                      {errors.lastName && <p className="error-text">{errors.lastName}</p>}
                    </div>
                </div>
                <div className="row form-group">
                  <div className="col-xs-12">
                    <label htmlFor="exampleInputEmail1">Work Email <span className="red">*</span></label>
                    <input type="email" placeholder="Your work email address" className={`form-control ${errors.email ? 'error':''}`} id="email" name="email" aria-describedby="emailHelp" onBlur={validate} onChange={handleChange} />
                    {errors.email && <p className="error-text">{errors.email}</p>}
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-xs-12 col-sm-6 col-md-6">
                    <label htmlFor="exampleInputEmail1">Company <span className="red">*</span></label>
                    <input type="text" placeholder="Your company name" className={`form-control ${errors.company ? 'error':''}`} id="company" name="company" aria-describedby="companyHelp" onBlur={validate} onChange={handleChange} />
                    {errors.company && <p className="error-text">{errors.company}</p>}
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6">
                    <label htmlFor="userIntention">I would like to <span className="red">*</span></label>
                    <select name="userIntention" id="userIntention" className={`form-control ${errors.userIntention ? 'error':''}`} onBlur={validate} onChange={handleChange}>
                      <option value="">Please select one</option>
                      <option value="demo">See a demo</option>
                      <option value="evaluation">Evaluate Meetapp for my organization</option>
                      <option value="pricing">Talk about pricing</option>
                      <option value="jointeam">Join our team</option>
                      <option value="feedback">Provide a feedback</option>
                      <option value="partnership">Talk about partnership opportunities</option>
                      <option value="support">Request support</option>
                    </select>
                    {errors.userIntention && <p className="error-text">{errors.userIntention}</p>}
                  </div>              
                </div>
                <div className="row form-group">
                  <div className="col-xs-12">
                    <label htmlFor="message">Message <span className="optional">(optional)</span></label>
                    <textarea placeholder="Tell us more" name="message" className="form-control" rows="5" id="message" onBlur={validate} onChange={handleChange} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <div className="disclosure">By clicking “Submit,” I acknowledge receipt of the Meetapp <Link to="/privacy">Privacy Policy</Link>.</div>
                    <button type="submit" className="submit btn btn-primary">Submit</button>
                  </div>
                </div>
              </form>
            }
            {success &&
              <div className="success-msg"><Lottie animationData={MessageSent} loop={false} /></div>
            } 
          </div>
        </div>
    </section>
  )
}
 
export default Contact;
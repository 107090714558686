import React from "react";
import { Link } from 'react-router-dom'; 
import ArrowBtn from "./ArrowBtn";
import './shape-btn.scss';
import useAnalyticsEventTracker from './../../helpers/useAnalyticsEventTracker';
 
function ShapeBtn(props) {
    let {ctaText, ctaUrl, color, arrow, shape, border} = props;
    const gaEventTracker = useAnalyticsEventTracker('ShapeBtn Click');
  return (
    <Link to={`/${ctaUrl}`} className={`shape-button ${shape} ${border}`} style={{backgroundColor: color}} onClick={()=>{gaEventTracker(`${ctaText} - url:${ctaUrl}`)}}>
      <ArrowBtn ctaText={ctaText} ctaUrl={ctaUrl} arrow={arrow}/>
    </Link>
  )
}
 
export default ShapeBtn;
import React from "react";
import Number from './../components/content/Number';
import LinkCard from './../components/button/LinkCard';
import './../css/about.scss';
import BlogData from './../data/blog.json';
import community from '../assets/community.jpg';
import { Link } from 'react-router-dom';
import {RiArrowDownSLine} from 'react-icons/ri';
import ArrowBtn from './../components/button/ArrowBtn';
import useAnalyticsEventTracker from './../helpers/useAnalyticsEventTracker';
import useAnalyticsScrollEvent from './../helpers/useAnalyticsScrollEvent';


function About(props) {
  let {featured, list} = BlogData.blog;
  const gaEventTracker = useAnalyticsEventTracker('About');
  useAnalyticsScrollEvent('About');
  return (
    <>

        <section className="about top-margin">
          <div className="container row center-xs center">
            <div className="intro col-xs-12 col-sm-8">
              <h1>Our Story</h1>
              <p>Meetapp was born by <b>listening to employees</b> at companies.</p>
              <p>We are a team of passionate individuals who have come together to build <b>the next workplace innovation.</b></p>
              <div className="btn"><ArrowBtn gaCategory={'About'} ctaText={'Join our team'} ctaUrl={'contact-sales'} arrow={true}/></div>
            </div>
          </div>
        </section>

      <section>
          <div className="container row middle-xs">
          <div className="col-xs-12 col-sm-4">
            <img src={community} />
          </div>
            <div className="col-xs-12 col-sm-8">
              <h2>Happiness is a social thing</h2>
              <p>Social relationships that employees share with each other are the most important factors to build a happy, healthy and professional environment at work.</p>
              <h3>Build a community</h3>
              <p>We believe that by fostering a sense of community and connection among employees, companies can significantly improve employee retention and engagement. 
                That's why our platform is designed to be user-friendly and intuitive, making it easy for employees to connect with each other and share information.</p> 
                <div className="numbers container row">
                  <div className="col-xs-6 col-sm-3"><Number number={'47.4M'} text={'leaving jobs yearly'} border={'border'} /></div>
                  <div className="col-xs-6 col-sm-3"><Number number={'57%'} text={'seeking friendship'} border={'border'} /></div>
                </div>          
            </div>
          </div>
      </section>


        <section className="bg-grey">
          <div className="container row center-xs center">
            <div className="intro col-xs-12 col-sm-10">
              <h1>Drop us a note!</h1>
              <p>We value your feedback and are always looking for ways to improve our platform and services.</p>
              <p>If you have any suggestions or comments, please let us know. We appreciate your support and look forward to hearing from you.</p>
              <br/><br/>
              <Link to="/contact-sales" onClick={()=>{gaEventTracker('Contact Sales Clicked','Button')}} className="sales-btn">Contact us</Link>
            </div>
          </div>
        </section>

        <section>
          <div className="container row center-xs center">
            <div className="col-xs-12 col-sm-8">
              <h2>Learn about employee retention</h2>
              <p>We understand that in today's fast-paced business world, employee retention is a critical issue for organizations of all sizes.</p>
            </div>
          </div>
          <div className="aboutcards container row center-xs middle-xs">
          <div className="col col-xs-12 col-sm-4">
            <LinkCard 
              eyebrow={featured.badge} 
              content={featured.headline}
              gaCategory={'About'}
              ctaUrl={'blog'} 
              border={'border'} 
              ctaText={'Learn more'}/>
          </div>
          {list.slice(0, 2).map((item, i) => {
            return (
                <div key={i} className="col col-xs-12 col-sm-4">
                  <LinkCard 
                    eyebrow={item.badge} 
                    content={item.headline}
                    gaCategory={'About'}
                    ctaUrl={'blog-article'} 
                    border={'border'} 
                    state={item}
                    ctaText={'Learn more'}/>
                </div>
              )
            })
          }
        </div>
        </section>

        
        
      
      
    </>
    
  )
}
 
export default About;
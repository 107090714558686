import React from "react";
import { Link } from 'react-router-dom';
import Intro from './../components/content/Intro';
import Card from './../components/content/Card';
// import LinkCard from './../components/button/LinkCard';
import ShapeBtn from '../components/button/ShapeBtn';
// import phone from '../assets/phone-profile.png';
import faces from '../assets/faces.png';
import downArrow from '../assets/down-arrow.png';
import step1 from '../assets/step1.png';
import step2 from '../assets/step2.png';
import step3 from '../assets/step3.png';
import employeeGraph from '../assets/employee-graph.png';
import integrations from '../assets/integrations.png';
import phoneSurvey from '../assets/phone-survey.png';
import retentionGraph from '../assets/graph-retention.png';
import line from '../assets/line.png';
import people from '../assets/people-icon.svg';
import workplace from '../assets/workplace-icon.svg';
import predictive from '../assets/predictive-icon.svg';
import rootCause from '../assets/root-cause-icon.svg';
import security from '../assets/security.svg';
import notifications from '../assets/notifications.svg';
import cloudSearch from '../assets/search-cloud.svg';
import womangroup from '../assets/woman-group.jpg';
import abstract from '../assets/abstract.svg';
import phoneactivities from '../assets/phone-activities.png';
import Lottie from "lottie-react";
import dashboard from "../assets/dash.json";
import './../css/enterprise.scss';
import useAnalyticsScrollEvent from './../helpers/useAnalyticsScrollEvent';

function Enterprise(props) {
  useAnalyticsScrollEvent('Enterprise');
  return (
    <>
   
   <section className="white top-margin">
      <div className="background-shape green"></div>
      <div className="background-container">
      <div className="intro-container">

        <div className="title-container">
          <h2 className="title-2">Insights with Relational Analytics</h2>
        </div>
        <div className="desc-container">
        Strengthen your organization from within leveraging real-time data to analyze, understand, and enhance the employee experience
        </div>

      </div>
        <div className="lottie-animation"><Lottie animationData={dashboard} loop={true} /></div>
      </div>
    </section>

 

   <section className="white">
        <div className="container row end-xs middle-xs">
          <div className="col-xs-12 col-sm-6">
            <div className="intro-container">

                <div className="title-container">
                  <div className="eyebrow">Dashboard</div>
                  <h2 className="title-2">Easing the HR Burden</h2>
                </div>
                <div className="desc-container">
                Our dashboard is a powerful tool, helping organizations gain valuable insights on employee engagement and satisfaction, make informed decisions, and ultimately achieve their objectives.
                </div>

                <ul className="checklist">
                  <li><b>Real-time KPIs & Analytics</b>, facilitate data-driven decision-making by providing real-time, relevant data to make timely informed decisions.</li>
                  <li><b>Benchmark</b> your performance against industry standards, helps identify areas where your organization excels or lags, revealing opportunities for improvement</li>                                  
                </ul>

              </div>
            </div>
            <div className="col-sm-1"></div>
            <div className="col-xs-12 col-sm-5">
              <img src={employeeGraph}/>
            </div>
        </div>

        <div className="white icon-text-section">
          <div className="container row">
            <div className="col-xs-12 col-sm-3">
              <div className="icon-text-container">
                <div className="icon"><img src={people}/></div>
                <div className="title">Relational analytics</div>
                <div className="content">Drive better business outcomes improving the employee experience, organizational culture and team dynamics.</div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-3">
              <div className="icon-text-container">
                <div className="icon"><img src={workplace}/></div>
                <div className="title">Workplace analytics</div>
                <div className="content">Inform decisions about workspace design, employee interactions and collaboration patterns to improve employee well-being and productivity</div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-3">
              <div className="icon-text-container">
                <div className="icon"><img src={predictive}/></div>
                <div className="title">Predictive Retention Score <span className="small">(beta)</span></div>
                <div className="content">Machine learning predictive analysis to address problem based on historical data and patterns.</div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-3">
              <div className="icon-text-container">
                <div className="icon"><img src={rootCause}/></div>
                <div className="title">Root Cause Analysis <span className="small">(beta)</span></div>
                <div className="content">Identification of underlying causes of turnover through analytics and feedback. Our AI will suggest actionable items.</div>
              </div>
            </div>
          </div>
        </div>
       
      </section>

     


      

      


      
        <div className="container row middle-xs">
       
          <div className="col-xs-12 col-sm-5">
            
          <div className="title-container">
                  {/* <div className="eyebrow">Finance</div> */}
                  <h2 className="title-2">KPIs Directly Attributed to Revenue</h2>
                 
                </div>
           <img src={retentionGraph}/>
           <div className="small-intro">
              Uncover the true financial impact of employee turnover on your organization. 
              Many businesses overlook the significant costs associated with employee departures.
            </div>
          </div>
          <div className="col-xs-12 col-sm-1"></div>


          <div className="col-xs-12 col-sm-6">
            <div className="intro-container">

               
               
             

                <ul className="checklist">
                  <li><b>Attrition cost</b> understand the various costs associated with attrition in order to help businesses implement strategies to reduce turnover and minimize its financial consequences</li>
                  <li><b>Benefit assessment</b> help organizations understand the value of their investments, prioritize projects, allocate resources efficiently, and make informed decisions on future initiatives</li> 
                  <li>Systematically evaluating <b>programs and events</b>, organizations can ensure that their initiatives are effective, relevant, and provide value to both employees and the company as a whole</li>                                   
                </ul> 
                
              </div>
            </div>
            
        </div>
      
      <div className="spacer-xxl"></div>


      <section className="bg-grey">
        <div className="container small">
          <div className="intro-container center">

              <div className="title-container">
                <div className="process-eyebrow"><span className="bar"></span> <span className="name">Process</span></div>
                <h2 className="title-2">Continuous assessment</h2>
              </div>

              <div className="process-container">
                <div className="row center-xs">
                  <div className="col-xs-4 col-sm-4">
                    <img className="center-step" src={step1}/>
                    <h5>Analzye</h5>
                    <p>Using real-time data & KPIs to stay proactive</p>
                  </div>
                  <div className="col-xs-4 col-sm-4">
                    <img className="center-step" src={step2}/>
                    <h5>Insight</h5>
                    <p>Predictive insight to forecast future trends and outcomes</p>
                  </div>
                  <div className="col-xs-4 col-sm-4">
                    <img className="center-step" src={step3}/>
                    <h5>Action</h5>
                    <p>Smart decision-making based on thorough analysis</p>
                  </div>
                </div>
              </div>
              
            </div>
        </div>
      </section>

      <section className="white top-margin">
        <div className="container row center-xs middle-xs">
          <div className="col-xs-12 col-sm-7">
            <div className="intro-container">

                <div className="title-container">
                  <div className="eyebrow">Communication</div>
                  <h2 className="title-2">Context-based surveys</h2>
                </div>
                <div className="desc-container">
                Leverage the powerful combination of psychological insights and data-driven analytics to unveil hidden patterns, enhance decision-making, and foster organizational growth.
                </div>

                <ul className="checklist">
                  <li><b>Contextual feedback</b> AI-driven approach determining the perfect time and place to connect with employees for insightful feedback</li>
                  <li><b>Employee communication</b> with instant reach notifications, ensuring your team stays informed, engaged, and connected</li>
                  <li><b>Employee Exit Data report</b> delivering comprehensive insights into employee exits root cause</li>
                  <li><b>Insightful daily report</b> using a synergy of data aggregation helping to stay proactive and foster a thriving work environment</li>
                </ul>
               
               
                
              </div>
            </div>
            {/* <div className="col-sm-1"></div> */}
            <div className="col-xs-12 col-sm-5 first-xs last-sm">
              <img src={phoneSurvey}/>
            </div>
        </div>
      </section>


      <section className="white">
        <div className="container row middle-xs">
       
          <div className="col-xs-12 col-sm-5">
           <img src={integrations}/>
          </div>
          <div className="col-xs-12 col-sm-1"></div>


          <div className="col-xs-12 col-sm-6">
            <div className="intro-container">

                <div className="title-container">
                  <div className="eyebrow">Integrations</div>
                  <h2 className="title-2">Seamless Integration</h2>
                </div>
                <div className="desc-container">
                Plug your data into relevant formulas. Seamlessly integrates with all your existing platforms to have a centralized view of business performance and strategic plans. 
                </div>
                <ul className="checklist">
                  <li>Utilize a comprehensive HR solution for a complete solution</li>
                  <li>Our platform adheres to the highest industry-grade security standards</li>
                  <li>Unified communication channels streamlining your organization's interactions</li>
                </ul>
                
              </div>
            </div>
            
        </div>
      </section>

     


     
      
    </>
    
  )
}
 
export default Enterprise;
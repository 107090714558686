import ReactGA from "react-ga4";

/* 
Category	String. Required. A top level category for these events. E.g. 'User', 'Navigation', 'App Editing', etc.
Action	String. Required. A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
Label	String. Optional. More precise labelling of the related action. E.g. alongside the 'Added a component' action, we could add the name of a component as the label. E.g. 'Survey', 'Heading', 'Button', etc.
*/

const useAnalyticsEventTracker = (category= "Meetapp") => {
  const eventTracker = (action = "action", label = "label") => {
    ReactGA.event({category, action, label});
  }
  return eventTracker;
}
export default useAnalyticsEventTracker;
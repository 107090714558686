// import React, { useState } from "react";
import React, {useState, useRef, useEffect} from 'react';
import { Link } from 'react-router-dom'; 
// import Switch from "../button/Switch";
import logo from './../../assets/logo-grey.png';
import './nav.scss';
import collaboration from './../../assets/img-collaboration.jpg';
import ShapeBtn from './../../components/button/ShapeBtn';
import {RiArrowDownSLine} from 'react-icons/ri';
import useOutsideClick from './../../helpers/useOutsideClick';
import BlogData from './../../data/blog.json';
import useAnalyticsEventTracker from './../../helpers/useAnalyticsEventTracker';

function Nav(props) {
  const ref = useRef();
  const [showResources, setShowResources] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState(false);
  const [resourceNavOpen, setResourceNavOpen] = useState(false);
  let {featured, list} = BlogData.blog;
  const gaEventTracker = useAnalyticsEventTracker('Navigation');

  useOutsideClick(ref, () => {
    setShowResources(false);
    if (!isOpen && window.pageYOffset < 5){
      setColor(false);
    }
  });

  const setNavColor = () => {
    if(!isOpen && !color){
      setColor(true);
    }
    if(color & window.pageYOffset < 5 && !isOpen){
      setColor(false);
    }
   
  }

  useEffect(() => {
    if (window.screen.height < 768){
      if(isOpen){
        document.body.style.overflow = 'hidden';
      }else{
        document.body.style.overflow = 'unset';
      }
    }
    
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>{
        if (window.pageYOffset > 5){
          setColor(true)
        }
        if (window.pageYOffset < 5){
          setColor(false)
          if (showResources && color){
            setColor(true)
          }
          if (!showResources && color){
            setColor(false)
          }
         
        }
        
      }
      );
    }
  });

  return (
    <>
    <header  className={`navigation ${showResources ? "on" : "off"} ${color ? "white" : "transparent"}`}>
          <div className="container">

            <div className='header-container'>

              <div className="logo-container">
                <Link to="/" onClick={()=>{gaEventTracker('Navigate to Home','Nav Link')}} className="item">
                  <img src={logo} className="logo" alt="Logo" />
                </Link>
              </div>

              <div className="navigation-sub left">                            
                <Link to="/platform" className="item" onClick={()=>{gaEventTracker('Navigate to Platform Desktop','Nav Link')}}>Employee</Link>
                <Link to="/enterprise" className="item" onClick={()=>{gaEventTracker('Navigate to Enterprise Desktop','Nav Link')}}>Enterprise</Link>
                <Link to="/why-meetapp" className="item why-meetapp" onClick={()=>{gaEventTracker('Navigate to Why Meetapp Desktop','Nav Link')}}>Why Meetapp</Link>
                <a ref={ref} className="item has-subnav" onClick={()=> {
                  setShowResources(!showResources);
                  gaEventTracker('Resources Menu Dropdown Desktop','Nav Link');
                  setNavColor();
                  }}>Resources <span className={showResources ? "on" : "off"}><RiArrowDownSLine size={20}/></span></a>
              </div>

              <div className="navigation-sub right">                            
                <a onClick={()=>{gaEventTracker('Navigate to Dashboard Sign in Desktop','Nav Link')}} href="https://www.dashboard.tomeetapp.com" className="item">Sign In</a>
                <Link onClick={()=>{gaEventTracker('Navigate to Contact Sales Desktop','Nav Link')}} to="/contact-sales" className="item button">Contact Sales</Link>
                {/* <Switch
                  isOn={value}
                  onColor="#06D6A0"
                  handleToggle={() => setValue(!value)}
                /> */}
              </div>

              <div
                onClick={() => {setIsOpen(!isOpen);gaEventTracker('Hamburger Menu Click','Button');}}
                className={`hamburger ${isOpen ? 'open' : ''}`}>
                <svg className="ham hamRotate ham1" viewBox="0 0 100 100" width="50">
                <path
                      className="line top"
                      d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40" />
                <path
                      className="line middle"
                      d="m 30,50 h 40" />
                <path
                      className="line bottom"
                      d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40" />
              </svg>
              </div>
              

            </div>
            
               

          </div>
          
      </header>

      <nav className={`mobile-menu ${isOpen ? 'open' : ''}`}>
        <div className="container">
          <Link onClick={() => {setIsOpen(false);gaEventTracker('Navigate to Platform Mobile','Nav Link');}} to="/platform" className="item">Employee</Link>
          <Link onClick={() => {setIsOpen(false);gaEventTracker('Navigate to Enterprise Mobile','Nav Link');}} to="/enterprise" className="item">Enterprise</Link>
          <Link onClick={() => {setIsOpen(false);gaEventTracker('Navigate to Why Meetapp','Nav Link');}} to="/why-meetapp" className="item">Why Meetapp</Link>
          <a className="item has-subnav" 
            onClick={()=> {setResourceNavOpen(!resourceNavOpen);gaEventTracker('Resources Menu Dropdown Mobile','Nav Link');}}>
            Resources 
            <span className={resourceNavOpen ? "on" : "off"}>
              <RiArrowDownSLine size={20}/>
            </span>
          </a>
          <div className={`resource-subnav ${resourceNavOpen ? 'open' : ''}`}>
            <Link onClick={() => {setIsOpen(false);gaEventTracker('Navigate to Employee Retention Mobile','Nav Link');}} to="/employee-retention" className="item">Employee Retention</Link>
            <Link onClick={() => {setIsOpen(false);gaEventTracker('Navigate to Solutions Mobile','Nav Link');}} to="/solutions" className="item">Solutions</Link>
            <Link onClick={() => {setIsOpen(false);gaEventTracker('Navigate to Blog Mobile','Nav Link');}} to="/blog" className="item">Blog</Link>
            <Link onClick={() => {setIsOpen(false);gaEventTracker('Navigate to FAQs Mobile','Nav Link');}} to="/faqs" className="item">FAQs</Link>
          </div>
          <Link onClick={() => {setIsOpen(false);gaEventTracker('Navigate to Contact Sales Mobile','Nav Link');}} to="/contact-sales" className="item button">Contact Sales</Link>
        </div>
      </nav>




      <div className={`sub-header-container ${showResources ? "on" : "off"}`} id="resources">
      <div className="container row">
        <div className="col col-xs-12 col-sm-4">
          <h6>Overview</h6>
          <div className="line"></div>
          <Link onClick={() => {setIsOpen(false);gaEventTracker('Navigate to Why Meetapp Desktop','Nav Link');}} to="/why-meetapp">
            <div className="navmenu-item">
              <div className="title">Why Meetapp</div>
              <div className="text">Learn more about our approach, and see why Meetapp should be your go-to partner for all things related to employee retention</div>
            </div>
          </Link>
          <Link onClick={() => {setIsOpen(false);gaEventTracker('Navigate to Solutions Desktop','Nav Link');}} to="solutions">
            <div className="navmenu-item">
              <div className="title">Solutions</div>
              <div className="text">Every company is unique and requires its own set of solutions to improve employee retention</div>
            </div>
          </Link>
          <Link onClick={() => {setIsOpen(false);gaEventTracker('Navigate to Blog Desktop','Nav Link');}} to="/blog">
            <div className="navmenu-item">
              <div className="title">Blog</div>
              <div className="text">From the latest research and trends to real-world case studies, our blog is your go-to resource for all things related to employee retention</div>
            </div>
          </Link>
          <Link onClick={() => {setIsOpen(false);gaEventTracker('Navigate to Employee Retention','Nav Link');}} to="/employee-retention">
            <div className="navmenu-item">
              <div className="title">Employee Retention</div>
              <div className="text">Read about the challenges companies are facing and solutions to improve employee engagement and reduce turnover</div>
            </div>
          </Link>
        </div>
        <div className="col navmenu-card-wrapper col-xs-12 col-sm-4 bg-grey">
          <h6>Featured Resources</h6>

          {list.slice(0, 3).map((item, i) => {   
            return (
              // <Link key={i} className="navmenu-card-container"  to={{
              //   pathname: "/blog-article",
              //   state: item
              // }} onClick={() => {gaEventTracker('Blog Article','Button Link');console.log('ie',item)}}>
              <Link key={i} className="navmenu-card-container"
                onClick={()=> {gaEventTracker(`Navigate to Blog Desktop | ${item.headline}`,'Nav Link');}}
                to="/blog-article"
                state={item}>
                <div className="navmenu-card">
                  <div className="eyebrow">{item.badge}</div>
                  <div className="text">{item.headline}</div>
                  <div className="cta">read more</div>
                </div>
              </Link>
            )
          })
        }
        </div>

        <div className="col featured col-xs-12 col-sm-4 bg-grey">
          <img src={collaboration}/>
          <p>{featured.description}</p>
          <Link to="/blog" onClick={() => {gaEventTracker('Navigate to Blog Desktop','Nav Link')}}>Learn more</Link>
        </div>

      </div>
    </div>
    </>
    
  )
}
 
export default Nav;
import React from "react";
import './card.scss';
 
function Card(props) {

    let {cardSize, bgColor, border, noGutter, eyebrow, title, subtitle, content, iconText, image, color} = props;

    return (
        <div className={`card-container ${bgColor} ${border} ${noGutter}`} style={{width:cardSize}}>
            <div className={`text-wrapper ${color}`}>
                { iconText && <span className="eyebrow iconText">{iconText}</span>}
                { eyebrow && <span className="eyebrow">{eyebrow}</span>}
                { title && <h2>{title}</h2>}
                { subtitle && <h4>{subtitle}</h4>}
                { content && <p>{content}</p>}
            </div>
            { image && <img src={image} alt={eyebrow}/>}
        </div>
    )
}
 
export default Card;
import React from "react";
import './../css/solutions.scss';
import card1 from '../assets/card1.png';
import card2 from '../assets/card2.png';
import card3 from '../assets/card3.png';
import card4 from '../assets/card4.png';
import card5 from '../assets/card5.png';
import card6 from '../assets/card6.png';
import card7 from '../assets/card7.png';
import card8 from '../assets/card8.png';
import card9 from '../assets/card9.png';
import laptop from '../assets/sq-laptop.png';
import idea from '../assets/sq-idea.png';
import useAnalyticsScrollEvent from './../helpers/useAnalyticsScrollEvent';

function Solutions(props) {
  useAnalyticsScrollEvent('Solutions');
  return (
    <>

      <section className="bg-grey top-margin">
        <div className="container row">
          <div className="intro-container solution-cards col-xs-12 col-sm-9">

            <div className="title-container">
              <div className="eyebrow">Enterprise Solutions</div>
              <h2 className="title-2">Solutions that fit every organizations</h2>
            </div>
            <div className="desc-container">
              Our platform is a comprehensive solution that includes a variety of tools and resources to help improve employee retention for your organization.
            </div>

            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-4">
                <div className="card">
                  <img src={card5}/>
                  <h5>Employee communication</h5>
                  <h6>Problem</h6>
                  <p>86% of employees cites lack of effective communication as the main causes for workplace failures.</p>
                  <h6>Solution</h6>
                  <p>Using our platfform you can instantly reach everyone within your organication via our <b>push notifications</b> feature.</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="card">
                  <img src={card1}/>
                  <h5>Employee sentiment & surveys</h5>
                  <h6>Problem</h6>
                  <p>67% of employees are not engaged at work</p>
                  <h6>Solution</h6>
                  <p>Using advanced technologies & psychology techniques, quickly identify the root causes of disengagement via our dashboard which gives a better understanding of employee sentiment and needs, allowing you to make more informed decisions.</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="card">
                  <img src={card7}/>
                  <h5>Accountability & recognition</h5>
                  <h6>Problem</h6>
                  <p>85% of employees would work harder if they felt their efforts were better recognized</p>
                  <h6>Solution</h6>
                  <p>Research shows that companies where coworkers are best friend typically experience 12% higher profit and employees 43% more likely to report receiving praise for their work.</p>
                </div>
              </div>
           
              <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="card">
                  <img src={card4}/>
                  <h5>Employee Onboarding</h5>
                  <h6>Problem</h6>
                  <p>60% of employees wish they had a more positive onboarding experience</p>
                  <h6>Solution</h6>
                  <p>Onboarding can be daunting and intimidating, our matching algorithm let employees pair up with a buddy based on their skills, interests, and availability to help with the onboarding journey.</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="card">
                  <img src={card2}/>
                  <h5>Employee connections & networking</h5>
                  <h6>Problem</h6>
                  <p>We're loosing touch, personal networks have shrunk by close to 16%</p>
                  <h6>Solution</h6>
                  <p>This is the main core of our service, providing a space where employee feel empowred to connect.
                    Helping employees discover and connect with other employees who have similar skills, interests, 
                    and experiences regardless of location or department.</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="card">
                  <img src={card6}/>
                  <h5>Employee events</h5>
                  <h6>Problem</h6>
                  <p>Average event attendance rate is around 50%</p>
                  <h6>Solution</h6>
                  <p>Facilitating the formation of employee-led groups and communities, which can foster a sense of belonging and connection among employees.</p>
                  <p>Greater engagement and participation in the workplace, which can lead to improved employee morale and retention.</p>
                </div>
              </div>

            </div>
              
          </div>
          <div className="col col-xs-12 col-sm-2 col-md-3">
            <img className="enterprise-solution-img" src={idea}/>
          </div>
        </div>
      </section>

      <section className="white">
      <div className="container row">

          <div className="col-xs-12 col-sm-3">
            <img className="team-solution-img" src={laptop}/>
          </div>

          <div className="intro-container solution-cards col-xs-12 col-sm-9">

            <div className="title-container">
              <div className="eyebrow">Teams Solutions</div>
              <h2 className="title-2">Build a community</h2>
            </div>
            <div className="desc-container">
              A workplace community is all about creating a shared sense of purpose and mutual trust. 
              It brings meaning beyond the daily grind and paycheck. 
              A positive work environment fosters strong team spirit and camaraderie. 
            </div>

            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-4">
                <div className="card bg-grey-outline">
                  <img src={card3}/>
                  <h5>Strong workplace culture</h5>
                  <h6>Problem</h6>
                  <p>88% of employees believe a strong company culture is key to business success</p>
                  <h6>Solution</h6>
                  <p>Studies reveal that 70 percent of employees say friends at work is the most crucial element to a happy working life.
                  Creating a positive workplace culture that is built on meaningful work, open communication, and core values.</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="card bg-grey-outline">
                  <img src={card9}/>
                  <h5>Health and mental wellness</h5>
                  <h6>Problem</h6>
                  <p>73% of employees wish their company would invest more in team building</p>
                  <h6>Solution</h6>
                  <p>Meetapp create opportunities for employees to engage in activities together by offering team-building events 
                    and activities, encouraging employees to socialize outside of work, 
                    and promoting a positive work environment where employees feel comfortable building relationships with their coworkers</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="card bg-grey-outline">
                  <img src={card8}/>
                  <h5>Work remote & travel</h5>
                  <h6>Problem</h6>
                  <p>61% of employees prefer being fully remote</p>
                  <h6>Solution</h6>
                  <p>Remote work is the biggest draw for top talent and yet 37% of remote workers reported feeling lonely
                  Additionally, traveling for work can be lonely and isolating, and having the opportunity to interact with coworkers can help alleviate those feelings. 
                  It's important for companies to find ways to connect remote workers and traveling employees with the rest of the team.
                  </p>
                </div>
              </div>
            </div>
              

              
          </div>
        </div>
      </section>

    </>
  )
}
 
export default Solutions;
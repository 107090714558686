import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

export default function ScrollToTop() {
    const { pathname, search } = useLocation();;

    
    useEffect(() => {
        
        // "document.documentElement.scrollTo" is the magic for React Router Dom v6
        document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant", // Optional if you want to skip the scrolling animation
        });

        //regex to remove "/", then replace "-" with space and capitalize each words
        let pageName = pathname.replace(/\//g,'').replace(/-/g,' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        //replace the page title on load 
        document.title = pageName ? pageName : 'Enterprise Social Platform | Meetapp';


        //Send page name to Google Analytics
        ReactGA.send({ hitType: "pageview", page: pathname + search });
        
    }, [pathname]);

  return null;
}
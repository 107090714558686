import React from "react";
import './number.scss';
 
function Number(props) {

    let {number, color, border, text} = props;

  return (
    <div className={`number-container ${color} ${border}`}>
      <div className="number">{number}</div>
      <div className="subnumber">{text}</div>
    </div>
  )
}
 
export default Number;
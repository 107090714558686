import React, {useState} from "react";
import {Waypoint} from 'react-waypoint';
import Lottie from "lottie-react";
import CountUp from 'react-countup';
import './../css/employee-retention.scss';
import truck from "../assets/truck-production.json";
import pieChart from "../assets/piechart.json";
import camping from "../assets/camping.jpg";
import logo from "../assets/logo-grey.png";
import groupactivities from '../assets/group-activities.png';
import useAnalyticsScrollEvent from './../helpers/useAnalyticsScrollEvent';



 
function EmployeeRetention(props) {
  useAnalyticsScrollEvent('Employee Retention');
  let [renderLottie, setRenderLottie] = useState(false);
  let [n, setNumber] = useState(1000000000000);
  const [value, setValue] = useState({ min: 10, max: 100000 });

  const formatCash = n => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "Million";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "Billion";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "Trillion";
  };

 
  
  const retentionRateLarge = 17.71;//percent
  const retentionRateSMB = 20.05;//percent
  const productivityTime = 2.5;//months 
  const retentionCostMultiplier = 1.5;
  const avgSalary = 91472;
  const minSalary = 91472;
  const maxSalary = 91472;
  let retentionRate = retentionRateSMB;


  const TurnoverRate = (employeeCount) => {
    retentionRate = retentionRateSMB;
    if(employeeCount > 999){
      retentionRate = retentionRateLarge;
    }else {
      retentionRate = retentionRateSMB;
    }
    return retentionRate;
  }

  const ProductivityLoss = (employeeCount) => {
    let timeLost;
    let months = (Math.round((employeeCount*retentionRate)/100)*productivityTime);
    if (months > 12){
      var years = Math.floor(months/12) + ' yr';
      months = months % 12 + ' mo';
      timeLost = years + months;
    }else {
      timeLost = months
    }
    return timeLost;
  }

  const TurnoverCost = (employeeCount) => {
    let retentionRate = TurnoverRate(employeeCount)
    let averageSalary = [(minSalary * 0.5) + (maxSalary * 2.5)]/2;
    let lostEmployee = Math.round((employeeCount*retentionRate)/100);
    return formatCash(averageSalary * lostEmployee);
  }
  const TurnoverCost2 = (employeeCount) => {
    return formatCash((Math.round((employeeCount*retentionRate)/100))*(avgSalary*1.5))
  }

  return (
    <>
    <section className="black">
    <section className="white">
      <div className="container row">
        <div className="col-xs-12">

          <Lottie animationData={truck} loop={true} style={{opacity: 0.6}}/>
          <div className="intro-container">
            <div className="title-container">
              <div className="eyebrow">The problem</div>
              <h1 className="title-2">Employee retention</h1>
            </div>
          </div>

          <p>In the US, job hopping remains a prevalent trend, with workers not deterred by the possibility of a recession and willing to switch jobs for better opportunities.</p>
          <br/><p>More than, <b>4 million workers quit their jobs each month</b> in the past year, according to <a href="https://www.bls.gov/news.release/pdf/jolts.pdf">US Bureau of Labor Statistics</a>.
            When just looking at voluntary quitting alone, 2021 saw a record number of voluntary quitting with over <b>47.4 million</b> throughout the year.</p>
        </div>
      </div>
    </section>

    <section className="white">
      <div className="container row">
       
          <div className="intro-container solution-cards col-xs-12 col-sm-11">
            <div className="title-container">
              {/* <div className="eyebrow">Costs</div> */}
              <h2>Financial burden</h2>
            </div>
            <div className="desc-container">
                <p>Voluntary employee turnover costs your business money even before an employee submits their letter of resignation.
                  with estimated costs of losing an employee ranging from 1.5 to 2 times their salary.</p><br/>
                  <p>When employees leave a company, it's not just their salary and benefits that are lost, 
                    but also a range of indirect costs that can have a significant impact on the organization:</p>
            </div>
            

            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-3">
                <div className="card bg-grey-outline">
                  {/* <img src={card3}/> */}
                  <h5>Recruitment Costs</h5>
                  <h6>Direct costs</h6>
                  <p>The process of hiring a new employee can be costly:
                    <ul className="sol-card-list">
                      <li>Advertising job postings</li>
                      <li>Interviewing</li>
                      <li>Screening</li>
                      <li>Hiring</li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="card bg-grey-outline">
                  {/* <img src={card9}/> */}
                  <h5>Onboarding Costs</h5>
                  <h6>Hidden Costs</h6>
                  <p>Bringing a new employee up to speed can be time-consuming and requires a significant investment in training and management time.</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="card bg-grey-outline">
                  {/* <img src={card8}/> */}
                  <h5>Lost Productivity</h5>
                  <h6>Hidden costs</h6>
                  <p>It can take a new hire up to two years to reach the same level of productivity as a current worker, which results in indirect costs to the organization.</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="card bg-grey-outline">
                  {/* <img src={card8}/> */}
                  <h5>Training Costs</h5>
                  <h6>Hidden costs</h6>
                  <p>Over the course of two to three years, a business may invest up to 20% or more of an employee's salary in training, adding to the cost of turnover</p>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-4">
                <div className="card bg-grey-outline">
                  <h5>Decreased Employee Engagement and Morale</h5>
                  <h6>Process</h6>
                  <p>When employees witness high turnover, it can result in disengagement and decreased productivity, affecting overall team morale</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="card bg-grey-outline">
                  <h5>Customer Service and Increased Errors</h5>
                  <h6>Process</h6>
                  <p>New employees often take longer to complete tasks and may be less proficient at problem-solving, which can impact customer satisfaction</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <div className="card bg-grey-outline">
                  <h5>Cultural Impact</h5>
                  <h6>Process</h6>
                  <p>The departure of a worker can prompt others to question why and may affect the overall culture and morale of the organization</p>
                </div>
              </div>
            </div> */}

          </div>
          
          <div className="col-xs-12 col-sm-3">
            {/* <img className="team-solution-img" src={laptop}/> */}
          </div>
      </div>
    </section>

    <section className="white">
      <div className="container row">
       
          <div className="intro-container solution-cards col-xs-12 col-sm-9">
            <div className="title-container">
              {/* <div className="eyebrow">Challenges</div> */}
              <h2>Domino effect</h2>
            </div>
            <div className="desc-container">
                <p>When an experienced employee leaves the company, their intangible contributions and productivity are lost, causing a dip in overall productivity. 
                  This can result in a gap until a replacement is hired and can lead to a decline in overall productivity. 
                  The cost of lost productivity in the US amounts to $1.8 trillion per year according to a report by HubSpot.</p><br/>
            </div>
            <ul className="checklist blackcross">
              <li><b>Turnover contagion phenomenon:</b> The departure of a coworker often prompts introspection among 
                remaining employees regarding the reasons for their exit and the potential for improved opportunities elsewhere. 
                This can lead to increased thoughts of leaving. </li>
              <li><b>Decreased Employee Engagement and Morale:</b> When employees are unhappy or witness high turnover,
                it can result in disengagement and decreased productivity, negatively impacting overall team morale, causing a <b style={{color:'#FF4D58'}}>loss of $7 trillion</b> in global productivity.</li>
              <li><b>Cultural Impact:</b> The best employees are often the ones who voluntarily leave an organization for a more attractive employer, 
              leading to a downward spiral as less skilled employees remain. This creates a negative cycle of disengagement.</li>
            </ul>

          </div>
          <div className="col-xs-12 col-sm-3">
            {/* <img className="team-solution-img" src={laptop}/> */}
          </div>
      </div>
    </section>

    <section className="bg-grey">
        <div className="container small">
          <div className="intro-container center">

              <div className="title-container">
                <h2 className="">Navigating the High Seas</h2>
              </div>
              <div className="desc-container">
              What began as pandemic-inspired isolation has grown into a turnover crisis.
              As more and more individuals work independently from their home location, building friends and meaningful connections at work becomes harder and harder. 
              Eventually, as new employees are onboarded, they immediately feel disconnected from pre-existing teams
              </div>
              
              
            </div>
        </div>
      </section>

    <section className="white">
      <div className="container row">
        <div className="col-xs-12 col-sm-4">
        <Waypoint onEnter={()=>setRenderLottie(true)} />
        {renderLottie && <Lottie animationData={pieChart} loop={false} /> }
        </div>
        <div className="col-xs-12 col-sm-8">
          <div className="intro-container">
            <div className="title-container">
              <h2 className="">The Great Resignation continues despite economic challenges</h2><br/>
            </div>
            <div className="desc-container">
              <p>A 2022 survey by Gartner revealed that, the pandemic has led <b>70% of employees to reevaluate the importance of work in their lives</b>, 
                shifted people's mindsets and priorities, particularly with regards to the value they place on their time.</p><br/>
              <div className="row center-xs intro-stats">
                <div className="col-xs-4 col-sm-4">
                  <h5>1 in 3</h5>
                  <p>employees feels disconnected from work</p>
                </div>
                <div className="col-xs-4 col-sm-4">
                  <h5>
                  <CountUp
                      start={0}
                      end={49}
                      duration={1}
                      delay={0}
                      suffix="%"
                      onEnd={() => console.log('Ended')}
                      onStart={() => console.log('Started')}>
                      {({ countUpRef, start }) => (
                        <div>
                          <span ref={countUpRef} />
                          <Waypoint onEnter={()=>start()} />
                        </div>
                      )}
                    </CountUp>
                  </h5>
                  <p>of employees are unhappy at work</p>
                </div>
                <div className="col-xs-4 col-sm-4">
                  <h5>
                  <CountUp
                      start={0}
                      end={46}
                      duration={1}
                      delay={0}
                      suffix="%"
                      onEnd={() => console.log('Ended')}
                      onStart={() => console.log('Started')}>
                      {({ countUpRef, start }) => (
                        <div>
                          <span ref={countUpRef} />
                          <Waypoint onEnter={()=>start()} />
                        </div>
                      )}
                    </CountUp>
                  </h5>
                  <p>of professionals plan to seek new employment in 2023</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section className="white">
      <div className="container row">
         
          <div className="intro-container solution-cards col-xs-12 col-sm-9">
            <div className="title-container">
              {/* <div className="eyebrow">HR</div> */}
              <h2>Stress on HR Professionals</h2>
            </div>
            <div className="desc-container">
                <p>"The Great Resignation," "The Great Attrition," "The Big Quit," or "The Great Reshuffle and Quiet Quitting," since 2021 has put significant pressure on HR professionals.</p><br/>
                <p>Rising voluntary departures pose a significant hurdle for HR departments, tasked with not just recruitment and training, but also financial implications turonver costs. 
                  This surge in turnover results in a pile-up of vacant roles requiring immediate attention, while maintaining an engaged, motivated workforce.</p><br/>
                  <p>The pandemic adds another layer of complexity, as HR navigates unprecedented remote work dynamics and fluctuating economic conditions. Despite these trials, 
                    <b style={{fontWeight: 600}}> it falls upon HR leaders to spearhead a transformative organizational culture, prioritizing a positive employee experience in the workplace.</b></p><br/>
            </div>
          </div>
          <div className="col-xs-12 col-sm-3">
            {/* <Lottie animationData={stressBattery} loop={false} /> */}
          </div>
        </div>
      </section>

      <section className="bg-grey">
        <div className="container small">
          <div className="intro-container center">
              <div className="title-container">
                <h2 className="">Difficulty in identifying and addressing the underlying causes of turnover</h2>
              </div>
              <div className="desc-container">
              Identifying and addressing the root causes of employee turnover can be a daunting task for HR professionals, as they navigate the complexities of the modern workforce.
              </div>
            </div>
        </div>
      </section>

      <section className="white">
      <div className="container row">
          
          <div className="intro-container solution-cards col-xs-12 col-sm-10">
            <div className="title-container">
              {/* <div className="eyebrow">Employees</div> */}
              <h2>Lack of connection and cohesion among employees</h2>
            </div>
            <div className="desc-container">
                <p>Employers are expecting to solve retention problems but have been overlooking the idea that employees are more likely to stick around when they have friends at work.</p><br/>
                <p>It becomes clear to see how the increased isolation and dependence on remote work has created a vacuum devoid of personal connection.</p><br/>
                <ul className="checklist blackcross">
                  <li><b>Compensation and benefits</b> alone only contribute to a small fraction (less than 10%) of voluntary employee departures.
                    The solution to retaining employees is not just increasing pay, but rather, providing better treatment.</li>
                  <li><b>In this era of hybrid and fully remote work</b>, it’s now more important than ever to utilize every tool in your toolbox. 
                  Ensuring employees develop an emotional connection to your company by making friends is necessary to minimize employee retention.</li>
                  <li><b>New hires lack the in-person bonding experiences</b> of former coworkers, so providing opportunities for employees 
                  to build relationships and learn about each other's interests both in and out of work is crucial.</li>
                </ul>                
            </div>
          </div>
        </div>
      </section>

      </section>

      <section className="top-margin-reduce image-bg-gradient">
        <div className="gradient"></div>
        <h2 className="title-2">You simply work better amongst friends</h2>
        <img className="team-solution-img" src={camping}/>
      </section>


      <section className="bg-grey top-margin-reduce">
        <div className="container small">
          <div className="intro-container center">
              <div className="title-container">
                {/* <h2 className="">You simply work better amongst friends</h2> */}
              </div>
              <div className="desc-container">
              Research has shown that there is a strong link between a sense of belonging and employee retention. 
              91% of employees who feel a sense of belonging being more likely to remain engaged at work. 
              </div>
            </div>
        </div>
      </section>

      <section className="white">
      <div className="container row">
          
          <div className="intro-container solution-cards col-xs-12 col-sm-9">
            <div className="title-container">
              <div className="eyebrow">Solution</div>
              <h2>Fostering a sense of belonging among employees</h2>
            </div>
            <div className="desc-container">
            <p>Individuals spend a significant portion of their lives at work and it is often where they form strong connections and friendships. 
                    Despite this, many companies do not establish systems to promote this aspect of workplace relationships.</p><br/>
                <p>Cultivating a culture of positivity and employee connectivity won’t happen overnight. It's gonna take a repetitive, focused effort, 
                  but it’s the one thing you can start to do right now that will dramatically minimize employee turnover, stimulate productivity and increase profitability.</p><br/>
                  
                  <div className="row intro-stats">
                <div className="col-xs-6 col-sm-4">
                  <h5>
                  <CountUp
                      start={0}
                      end={57}
                      duration={1}
                      delay={0}
                      suffix="%"
                      onEnd={() => console.log('Ended')}
                      onStart={() => console.log('Started')}>
                      {({ countUpRef, start }) => (
                        <div>
                          <span ref={countUpRef} />
                          <Waypoint onEnter={()=>start()} />
                        </div>
                      )}
                    </CountUp>
                  </h5>
                  <p>of workers say it's more fun and enjoyable to work when they have a good friend at the workplace</p>
                </div>
                <div className="col-xs-6 col-sm-4">
                  <h5>
                  <CountUp
                      start={0}
                      end={22}
                      duration={1}
                      delay={0}
                      suffix="%"
                      onEnd={() => console.log('Ended')}
                      onStart={() => console.log('Started')}>
                      {({ countUpRef, start }) => (
                        <div>
                          <span ref={countUpRef} />
                          <Waypoint onEnter={()=>start()} />
                        </div>
                      )}
                    </CountUp>
                  </h5>
                  <p>of people say they are more productive with a friend at work, and it helps them be more creative</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-3">
            {/* <img className="team-solution-img" src={laptop}/> */}
          </div>
        </div>
     
     
    </section>  

    <section className="bg-grey">
        <div className="container small">
          <div className="intro-container center">
              <div className="title-container">
              {/* <div className="eyebrow">The role of Employee Experience App</div> */}
                <h2 className="">The role of Employee Experience App</h2>
              </div>
              <div className="desc-container">
              The foundation of a great employee retention platform is to create a social workplace community for your organization.
              </div>
            </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container small">
          <div className="intro-container center">

              <div className="title-container">
                <img className="title-logo" src={logo}/>
                <h2 className="title-2">We bring teams together</h2>
              </div>

              <div className="desc-container center">
                  {/* MeetApp® is a mobile platform to create a socially connected & inclusive work environment, resulting in increases in employee retention, 
                  higher productivity and reduced hiring costs. */}
                  <p>Meet App is a powerfully immersive employee experience app designed develop employee friendships through shared experiences and interests from inside and outside the workplace and combat workplace silos.</p><br/>
              </div>
             
              
            </div>
      
            <div className="activities-bg">
              <img src={groupactivities}/>
            </div>
        </div>
      </section>


      <section className="white">
      <div className="container row">
          
          <div className="intro-container solution-cards col-xs-12 col-sm-10">
            <div className="title-container">
              <div className="eyebrow">Enterprise Social Platform</div>
              <h2>Equip your HR team </h2>
            </div>
            <div className="desc-container">
                <p>Building and maintaining social connections is not always a natural process. Some individuals may struggle to form connections.
                  To address these challenges, leveraging technology through the use of employee experience app, can greatly aid in the facilitation of forming social connections. </p><br/>
                <p>Facilitating opportunities for employees to participate in activities aligned with their interests enhances 
                  the likelihood of forming connections with individuals with similar passions. 
                  This creates a more relaxed atmosphere for getting to know others and can reduce the discomfort often associated with social interactions, making it a less intimidating experience.</p><br/>
                  <p>Whether they are in-office employees, hybrid or fully remote, it connects individuals to stimulate engagement, motivation so they don’t feel left out of the company’s core culture</p>         
            </div>
          </div>
        </div>
        <div className="container row">
       
       <div className="intro-container solution-cards col-xs-12 col-sm-12">

           <h3 style={{textAlign: 'center'}}>Explore the intricate benefits from having a friend at work</h3><br/>
         
         <div className="row">
           <div className="col-xs-12 col-sm-6">
             <div className="card bg-grey-outline">
             <h5>Positive Workplace Culture</h5>
               <p>Cultivating a culture of positivity provide a sense of <b>work-life balance</b> Employees who have friends at work are 1.4 times more likely 
                 to <b>receive praise and recognition</b> from their peers, rather than just from management which relieves stress from management and 137% more support for their <b>professional growth</b></p>
             </div>
           </div>
           <div className="col-xs-12 col-sm-6">
           <div className="card bg-grey-outline">
           <h5>Boost mental health</h5>
               <p> According to the American Psychological Association (APA), the correlation between health and stress is evident. However, 
                 having access to social and emotional support, whether it be in the home or at work, can help to alleviate stress and improve our overall well-being.</p>
             </div>
           </div>
         </div>

         <div className="row">
           <div className="col-xs-12 col-sm-4">
             <div className="card bg-grey-outline">
               <h5>Increase engagement</h5>
               <p>friends work better together to solve problems more effectively, making it easier to handle high-pressure situations, 
                 higher quality work, and a greater sense of well-being</p>
             </div>
           </div>
           <div className="col-xs-12 col-sm-4">
           <div className="card bg-grey-outline">
               <h5>Improve communication</h5>
               <p>Workplace friendships help facilitate better communication through shared understandings. Having a bit of friendly competition in the workplace 
                 helps push everyone to produce higher quality work</p>
             </div>
           </div>
           <div className="col-xs-12 col-sm-4">
           <div className="card bg-grey-outline">
               {/* <img src={card8}/> */}
               <h5>Eliminate burnout</h5>
               <p> Work can become repetitive and monotonous, leading to workplace burnout for many individuals. Employees who have supportive 
                 relationships with their coworkers are less likely to experience burnout and tend to be more productive.</p>
             </div>
           </div>
         </div>
         <div className="row">
           <div className="col-xs-12 col-sm-6">
           <div className="card bg-grey-outline">
               <h5>Increase profitability</h5>
               <p>Gartner's research uncovered that when at least 60% of employees within the entire company work with a good friend, 
                 it improves everything from customer engagement to profitability up to 12%.</p>
             </div>
           </div>
           <div className="col-xs-12 col-sm-6">
             <div className="card bg-grey-outline">
               <h5>Smart onboarding strategies</h5>
               <p>A positive company culture attracts and retains top talent. The initial weeks in a new organization present a chance to
               foster relationships, and establish friendships that will enable their success in their role. A good onboarding program leads to 69% of employees staying at least 3 years.
               </p>
             </div>
           </div>
         </div>

       </div>
       
       <div className="col-xs-12 col-sm-3">
         {/* <img className="team-solution-img" src={laptop}/> */}
       </div>
   </div>
      </section>

     


   

    {/* <section className="white">
      <div className="container row">
        <div className="col-xs-12 col-sm-12">
              <div className="circle-container">
                <div className="circle large">
                  <div>
                    <CountUp
                      start={0}
                      end={12}
                      duration={1}
                      suffix="M"
                      onEnd={() => console.log('Ended')}
                      onStart={() => console.log('Started')}>
                      {({ countUpRef, start }) => (
                        <div>
                          <span ref={countUpRef} />
                          <Waypoint onEnter={()=>start()} />
                        </div>
                      )}
                    </CountUp>
                    <div className="sub">active users</div>
                  </div>
                </div>
                <div className="circle medium">
                  <div>
                    <CountUp
                      start={0}
                      end={12}
                      duration={1}
                      delay={1}
                      suffix="M"
                      onEnd={() => console.log('Ended')}
                      onStart={() => console.log('Started')}>
                      {({ countUpRef, start }) => (
                        <div>
                          <span ref={countUpRef} />
                          <Waypoint onEnter={()=>start()} />
                        </div>
                      )}
                    </CountUp>
                    <div className="sub">active users</div>
                  </div>
                </div>
                <div className="circle small">
                  <div>
                    <CountUp
                      start={0}
                      end={12}
                      duration={1}
                      delay={2}
                      suffix="M"
                      onEnd={() => console.log('Ended')}
                      onStart={() => console.log('Started')}>
                      {({ countUpRef, start }) => (
                        <div>
                          <span ref={countUpRef} />
                          <Waypoint onEnter={()=>start()} />
                        </div>
                      )}
                    </CountUp>
                    <div className="sub">active users</div>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </section> */}

   
   

  

   

  
    </>
  )
}
 
export default EmployeeRetention;
import React from "react";
import './../content/card.scss';
import ArrowBtn from './ArrowBtn';
import { Link } from 'react-router-dom';
import useAnalyticsEventTracker from './../../helpers/useAnalyticsEventTracker';
import {RiArrowRightSLine, RiArrowRightLine} from 'react-icons/ri';
 
function LinkCard(props) {
    
    let {cardSize, bgColor, border, noGutter, eyebrow, title, subtitle, content, icon, image, color, ctaText, ctaUrl, gaCategory, state} = props;
    const gaEventTracker = useAnalyticsEventTracker(gaCategory);

  return (
      <Link to={`/${ctaUrl}`} 
        className={`card-container ${bgColor} ${border} ${noGutter}`} 
        style={{width:cardSize}} 
        state={state} 
        onClick={()=>{gaEventTracker(`${ctaText} clicked navigate to:${ctaUrl} | ${content}`,'Card Link')}}>
          <div className={`text-wrapper ${color}`}>
              { icon && <span className="eyebrow icon">{icon}</span>}
              { eyebrow && <span className="eyebrow">{eyebrow}</span>}
              { title && <h2>{title}</h2>}
              { subtitle && <h4>{subtitle}</h4>}
              { content && <p>{content}</p>}
              { ctaText && 
                <span to={`/${ctaUrl}`} className={`arrow-button arrow`}>
                    <span>{ctaText}</span>
                    <span className="nohover"><RiArrowRightSLine size={20}/></span>
                    <span className="hover"><RiArrowRightLine size={20}/></span>
                </span>
              }
          </div>
          { image && <img src={image} alt={eyebrow}/>}
      </Link>
  )
}
 
export default LinkCard;
import React, {useState, useRef, useEffect} from "react";
import {Waypoint} from 'react-waypoint';
import Lottie from "lottie-react";
import HeroGraph from "./../assets/graph-hero2.svg";
import CountUp from 'react-countup';
import './../css/whymeetapp.scss';
import logo from "../assets/logo-grey.png";
import innerGraph from "../assets/innergraph2.png";
import peopleEvent from "../assets/playing-games.jpg";
import benefitGraph from "../assets/benefit-graph.png";
import files from "../assets/files.json";
import { FaArrowUp } from 'react-icons/fa';
import Slider from './../components/slider/Slider';
import {Tabs, Tab} from './../components/tab/Tab';
import { Link } from 'react-router-dom';
import womangroup from '../assets/woman-group.jpg';
import abstract from '../assets/abstract.svg';
import TimelineObserver from "react-timeline-animation";
import { fireConfetti } from "./../helpers/confetti";
import useAnalyticsEventTracker from './../helpers/useAnalyticsEventTracker';
import useAnalyticsScrollEvent from './../helpers/useAnalyticsScrollEvent';
import { BsFillArrowUpRightCircleFill, BsFillArrowDownRightCircleFill } from 'react-icons/bs';



const Timeline = ({ setObserver, callback }) => {
  const gaEventTracker = useAnalyticsEventTracker('Why Meetapp Progress');

  const [title1, setTitle1] = useState("");
  const [title2, setTitle2] = useState("");
  const [title3, setTitle3] = useState("");
  const [title4, setTitle4] = useState("");
  const [title5, setTitle5] = useState("");

  const [message1, setMessage1] = useState("");
  const [message2, setMessage2] = useState("");
  const [message3, setMessage3] = useState("");
  const [message4, setMessage4] = useState("");
  const [message5, setMessage5] = useState("");

  const timeline1 = useRef(null);
  const timeline2 = useRef(null);
  const timeline3 = useRef(null);
  const timeline4 = useRef(null);
  const timeline5 = useRef(null);
  const circle1 = useRef(null);
  const circle2 = useRef(null);
  const circle3 = useRef(null);
  const circle4 = useRef(null);
  const circle5 = useRef(null);

  const someCallback = () => {
    setTitle1("Inspiration");
    setMessage1("Driven by a desire to create a positive impact in the world of work, we are focused on enhancing employee's life. We got inspired to develop a platform that provides cutting-edge tools and strategies to foster a thriving workplace environment, which ultimately benefits both employees and businesses.");
    callback();
    gaEventTracker('Startup progress - Start','Scroll');
  };

  const someCallback2 = () => {
    setTitle2("Market");
    setMessage2("Undertaking market research as been a detailed and enlightening process. We delved into understanding the dynamics of the employee retention landscape, studying current trends, challenges, and the various solutions already existing in the market. We identified our potential users, ranging from HR professionals to corporate leaders and employees, and explored their needs, preferences, and pain points.");
  };

  const someCallback3 = () => {
    setTitle3("Team");
    setMessage3("We are focused on bringing together individuals with the right mix of skills, experience, and cultural fit. Prioritizing individuals who demonstrate not only technical competence but also adaptability, creative problem-solving abilities, and a shared passion for our mission. The result is a diverse, multidisciplinary team that embodies a balance of technical prowess and innovative thinking.");
  };

  const someCallback4 = () => {
    setTitle4("MVP");
    setMessage4("Working on the concept development has been an iterative and insight-driven journey. Through surveys and interviews, we gleaned valuable insights about what our target audience seeks in an employee retention solution. We also conducted a competitive analysis to understand our industry peers' strengths and gaps. This extensive research has formed the foundation of our product development, guiding us in creating a mobile platform that directly addresses market needs and stands out amongst the competition.");
    fireConfetti();
    gaEventTracker('Startup progress - End','Scroll');
  };

  const someCallback5 = () => {
    setTitle5("Launch");
    setMessage5("While going through rigorous stages of idea generation, MVP development, testing, and refining based on feedback, we're soon about to reach a point where our product will be ready for the public eye. We strategically planned our launch, building anticipation through a carefully executed marketing campaign.");
    
  };

  useEffect(() => {
    setObserver(timeline1.current);
    setObserver(timeline2.current);
    setObserver(timeline3.current);
    setObserver(timeline4.current);
    // setObserver(timeline5.current);
    setObserver(circle1.current, someCallback);
    setObserver(circle2.current, someCallback2);
    setObserver(circle3.current, someCallback3);
    setObserver(circle4.current, someCallback4);
    // setObserver(circle5.current, someCallback5);
  }, []);

  return (
    <div className="timeline-wrapper">
      <div className="row">
        <div className="col-xs-12">
      <div id="timeline1" ref={timeline1} className="timeline" />
      <div className="circleWrapper">
        <div id="circle1" ref={circle1} className="circle">
          1
        </div>
        <div className="title">{title1}</div>
        <div className="message">{message1}</div>
      </div>
      <div id="timeline2" ref={timeline2} className="timeline" />
      <div className="circleWrapper">
        <div id="circle2" ref={circle2} className="circle">
          2
        </div>
        <div className="title">{title2}</div>
        <div className="message">{message2}</div>
      </div>
      <div id="timeline3" ref={timeline3} className="timeline" />
      <div className="circleWrapper">
        <div id="circle3" ref={circle3} className="circle">
          3
        </div>
        <div className="title">{title3}</div>
        <div className="message">{message3}</div>
      </div>
      <div id="timeline4" ref={timeline4} className="timeline" />
      <div className="circleWrapper">
        <div id="circle4" ref={circle4} className="circle">
          4
        </div>
        <div className="title">{title4}</div>
        <div className="message">{message4}</div>
      </div>
      {/* <div id="timeline5" ref={timeline5} className="timeline" />
      <div className="circleWrapper">
        <div id="circle5" ref={circle5} className="circle">
          5
        </div>
        <div className="title">{title5}</div>
        <div className="message">{message5}</div>
      </div> */}

      </div>
   
      </div>
    </div>
  );
};
 
function WhyMeetapp(props) {
  const gaEventTracker = useAnalyticsEventTracker('Why Meetapp');
  useAnalyticsScrollEvent('Why Meetapp');
  
  let [renderLottie, setRenderLottie] = useState(false);
  let [n, setNumber] = useState(1000000000000);
  const [value, setValue] = useState({ min: 10, max: 100000 });
  // const [qty, setQty] = useState('Billions');
  let qty = 'Billions';

  const onCallback = () => {
    // do something
  };

  useEffect(() => {

      setValue({ min: 22850, max: 100000  });

  }, []);

  const formatCash = n => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };

 
  
  const retentionRateLarge = 17.71;//percent
  const retentionRateSMB = 20.05;//percent
  const productivityTime = 2.5;//months 
  const retentionCostMultiplier = 1.5;
  const avgSalary = 91472;
  const minSalary = 91472;
  const maxSalary = 91472;
  let retentionRate = retentionRateSMB;

  const sliderPercentage = () => {
    return value.min/value.max;
  }

  const TurnoverRate = (employeeCount) => {
    let difference = retentionRateSMB - retentionRateLarge;
    let increase = difference * sliderPercentage()
    retentionRate = retentionRateSMB - increase;
    // if(employeeCount > 999){
    //   retentionRate = retentionRateLarge;
    // }else {
    //   retentionRate = retentionRateSMB;
    // }
    return retentionRate.toFixed(2);
  }

  const Disengagement = (employeeCount) => {
    let rate = TurnoverRate(employeeCount);
    let employeeLoss = (employeeCount * rate)/100;
    let disengaged = Math.round(employeeLoss * 1.7).toLocaleString("en-US");
    return disengaged;
  }

  const engagePercentage = (employeeCount) => {
    let rate = TurnoverRate(employeeCount);
    let employeeLoss = (employeeCount * rate)/100;
    let disengaged = Math.round(employeeLoss * 1.7);
    let engaged = value.min - Math.round(employeeLoss * 1.7);
    let percentage = ((engaged/value.min)*100).toFixed(1);
    return percentage;
  }

  const disengagePercentage = (employeeCount) => {
    let rate = TurnoverRate(employeeCount);
    let employeeLoss = (employeeCount * rate)/100;
    let disengaged = Math.round(employeeLoss * 1.7);
    let percentage = ((disengaged/value.min)*100).toFixed(1);
    return percentage;
  }

  const ProductivityLoss = (employeeCount) => {
    let timeLost = productivityTime/12;
    console.log('timeLost',timeLost)
    let months = Math.round(Math.round((employeeCount*retentionRate)/100)*timeLost/100);
    return months;
  }

  const TurnoverCost = (employeeCount) => {
    let retentionRate = TurnoverRate(employeeCount)
    let averageSalary = [(minSalary * 0.5) + (maxSalary * 2.5)]/2;
    let lostEmployee = Math.round((employeeCount*retentionRate)/100);
    return formatCash(averageSalary * lostEmployee);
  }
  const TurnoverCost2 = (employeeCount) => {
    return formatCash((Math.round((employeeCount*retentionRate)/100))*(avgSalary*1.5))
  }

  return (
    <>
    <section className="white top-margin">
    <div className="background-shape longer red"></div>
      <div className="container row center-xs">

        <div className="col-sm-5 last-xs">
          <Lottie className="noMobile" animationData={files} loop={true} />
        </div>
        <div className="col-xs-12 col-sm-7">
          <div className="intro-container">
            <div className="title-container">
              <div className="eyebrow">Employee retention</div>
              <h2 className="title-2">A Priority for Businesses</h2>
            </div>
              <div className="desc-container row">
            Traditional approaches to employee retention have not kept pace with the changing needs of the modern workforce, 
            and many companies are struggling to find new and effective ways to engage and retain their employees.
            </div>

            <div className="row first-xs intro-stats">
                  <div className="col-xs-4 col-sm-4">
                    {/* <h5>33%</h5> */}
                    <h5>
                    <CountUp
                      start={0}
                      end={33}
                      duration={1}
                      delay={0}
                      suffix="%"
                      onEnd={() => console.log('Ended')}
                      onStart={() => console.log('Started')}>
                      {({ countUpRef, start }) => (
                        <div>
                          <span ref={countUpRef} />
                          <Waypoint onEnter={()=>start()} />
                        </div>
                      )}
                    </CountUp>
                    </h5>
                    <p>of our entire lifetime is spent at work with coworkers</p>
                  </div>
                  <div className="col-xs-4 col-sm-4">
                    {/* <h5>87%</h5> */}
                    <h5>
                    <CountUp
                      start={0}
                      end={87}
                      duration={1}
                      delay={0}
                      suffix="%"
                      onEnd={() => console.log('Ended')}
                      onStart={() => console.log('Started')}>
                      {({ countUpRef, start }) => (
                        <div>
                          <span ref={countUpRef} />
                          <Waypoint onEnter={()=>start()} />
                        </div>
                      )}
                    </CountUp>
                    </h5>
                    <p>of employees are actively disengaged at work</p>
                  </div>
                  <div className="col-xs-4 col-sm-4">
                    {/* <h5>44%</h5> */}
                    <h5>
                    <CountUp
                      start={0}
                      end={44}
                      duration={1}
                      delay={0}
                      suffix="%"
                      onEnd={() => console.log('Ended')}
                      onStart={() => console.log('Started')}>
                      {({ countUpRef, start }) => (
                        <div>
                          <span ref={countUpRef} />
                          <Waypoint onEnter={()=>start()} />
                        </div>
                      )}
                    </CountUp>
                    </h5>
                    <p>reported a lack of connection with coworkers</p>
                  </div>
                </div>

          </div>
        </div>
        
      </div>
    </section>


    <section className="white">

<div className="container row">
   
   <div className="col-xs-12 col-sm-12">
     <div className="intro-container">
       <div className="title-container">
         <div className="eyebrow">Financial Impact</div>
         <h2 className="title-2">An expensive reality</h2>
       </div>


     <div className="desc-container row">
     Many businesses overlook the significant costs associated with employee departures and the loss of one employee puts excessive pressure on the surviving workforce, 
     causing a chain reaction of overwork, burnout, discontent, and rising turnover. 
     {/* Voluntary employee turnover costs your business money even before an employee submits their letter of resignation. */}
     

     
     

     </div>
     </div>


   </div>

  

 </div>

<div className="container row center-xs center">
      <div className="hero-graph">
       
          <div className="row graph-content">
          <div className="row percent center-xs"><span><FaArrowUp size={15}/>88%</span> since 2010</div>
          <div className="row number-animation">
            <div className="col-xs-8">
            <h4 className="num"> 
              <CountUp
                start={0}
                end={n}
                duration={1.75}
                useEasing={false}
                formattingFn={(n)=>{
                  if (n >= 1e9 && n < 1e12) return +Math.trunc((n / 1e9).toFixed(1)) + " B";
                  if (n >= 1e12) return +(n / 1e12).toFixed(1)+ " Trillion";
                }}
                onEnd={() => {}}
                onStart={() => {gaEventTracker('Viewed retenttion graph','Graph')}}>
                {({ countUpRef, start }) => (
                  <div>
                    <span ref={countUpRef} /> 
                    <Waypoint onEnter={()=>start()} />
                  </div>
                )}
              </CountUp>
            </h4>
            </div>  
          
            
            </div>

            <div className="row eyebrow">turnover cost per year in the US</div>
          </div>
          <img src={HeroGraph} />
        </div>
          <div className="col-xs-10 hero-desc">
          {/* According to Gallup, the cost of voluntary employee turnover in the United States is estimated to be a staggering $1 trillion for businesses. */}
          This estimate from <a href="https://www.gallup.com/workplace/247391/fixable-problem-costs-businesses-trillion.aspx">Gallup</a> highlights the significant financial impact high turnover rates can have on companies, and the importance of <b>implementing strategies to reduce it</b>.
          {/* The financial impact is substantial and high turnover rates could threaten the sustainability of the business. */}
          </div>
    </div>

</section>

<section>
<div className="container row">
    <div className="col-xs-12">
    <div className="intro-container center">
        <div className="title-container">
          
          <h2 className="title-2">Estimate your cost</h2>
          <div className="desc-container center">
            Uncover the financial impact employee turnover could have on your organization. 
          </div>
        </div>
        
      </div>

      

      <div className="spacer-lg"></div>

      <div className="desc-container">
             
              <div className="employee-container">
                <h3 className="case-number-title">What's your employee count?</h3>
                <h1 className="employee-number">{(value.min).toLocaleString("en-US")}</h1>
              </div>
               
              <Slider min={10} max={100000} step={10} value={value} onChange={setValue} />


              {/* <div className="case-number-container">
                <div className="case-number-card turnover">
                  <div>
                    <div className="case-number">{TurnoverRate(value.min)}%</div>
                    <div className="case-number-title">Turnover rate</div>
                  </div>
                </div>
                <div className="case-number-card attrition">
                  <div>
                    <div className="case-number">${TurnoverCost(value.min)}</div>
                    <div className="case-number-title">Attrition cost</div>
                  </div>
                </div>
                <div className="case-number-card disengagement">
                  <div>
                    <div className="case-number">{Disengagement(value.min)}</div>
                    <div className="case-number-title">Disengagement</div>
                  </div>
                </div>
                <div className="case-number-card productivity">
                  <div>
                    <div className="case-number">{ProductivityLoss(value.min)}%</div>
                    <div className="case-number-title">Productivity loss</div>
                  </div>
                </div>
              </div> */}

              <div className="slider-card-container">
                <div className="row container">
                  <div className="col-xs-12 col-sm-3"> 
                    <div className="slider-card red">
                      <div className="row">
                        <div className="col-xs-10">
                          <h6>Attrition cost</h6>
                        </div>
                        <div className="col-xs-2">
                          <div className="trend down"><BsFillArrowUpRightCircleFill size={25} fill={"#FC4C77"}/></div>
                        </div>
                      </div>
                      <div className="number">${TurnoverCost(value.min)}</div>
                      <div className="graph-details bottom">
                        <div className="row bottom-xs">

                          <div className="row">
                            <div className="col-xs-3"><div className="bar" style={{height:`${((value.min/value.max)*100)*0.28}px`}}></div></div>
                            <div className="col-xs-3"><div className="bar" style={{height:`${((value.min/value.max)*100)*0.55}px`}}></div></div>
                            <div className="col-xs-3"><div className="bar" style={{height:`${((value.min/value.max)*100)*0.72}px`}}></div></div>
                            <div className="col-xs-3"><div className="bar" style={{height:`${((value.min/value.max)*100)*0.45}px`}}></div></div>
                          </div>

                          <div className="row quarter-wrapper">
                            <div className="col-xs-3"><div className="quarter">Q1</div></div>
                            <div className="col-xs-3"><div className="quarter">Q2</div></div>
                            <div className="col-xs-3"><div className="quarter">Q3</div></div>
                            <div className="col-xs-3"><div className="quarter">Q4</div></div>
                          </div>

                          <div className="line four"></div>
                          <div className="line three"></div>
                          <div className="line two"></div>
                          <div className="line one"></div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-3">
                    <div className="slider-card purple">
                      <div className="row">
                        <div className="col-xs-10">
                          <h6>Disengagement</h6>
                        </div>
                        <div className="col-xs-2">
                        <div className="trend down"><BsFillArrowUpRightCircleFill size={25} fill={"#FC4C77"}/></div>
                        </div>
                      </div>
                      <div className="number">{Disengagement(value.min)}</div>
                      <div className="details bottom">
                        <div className="row middle-xs">
                          <div className="col-xs-2"><div className="dark"></div></div>
                          <div className="col-xs-7">Engaged</div>
                          <div className="col-xs-3">{engagePercentage(value.min)}%</div>
                        </div>
                        <div className="row middle-xs">
                        <div className="col-xs-2"><div className="light"></div></div>
                          <div className="col-xs-7">Disengaged</div>
                          <div className="col-xs-3">{disengagePercentage(value.min)}%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-3">
                    <div className="slider-card green">
                      <div className="row">
                        <div className="col-xs-10">
                          <h6>Productivity loss</h6>
                        </div>
                        <div className="col-xs-2">
                        <div className="trend down"><BsFillArrowUpRightCircleFill size={25} fill={"#FC4C77"}/></div>
                        </div>
                      </div>
                      <div className="number">{ProductivityLoss(value.min)}%</div>
                      <div className="details bottom">
                        <div className="row middle-xs">
                          <div className="col-xs-2"><div className="dark"></div></div>
                          <div className="col-xs-7">Productive</div>
                          <div className="col-xs-3">{((1-(ProductivityLoss(value.min)/100))*value.min).toFixed(0)}</div>
                        </div>
                        <div className="row middle-xs">
                        <div className="col-xs-2"><div className="light"></div></div>
                          <div className="col-xs-7">Unproductive</div>
                          <div className="col-xs-3">{((ProductivityLoss(value.min)/100)*value.min).toFixed(0)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-3">
                    <div className="slider-card yellow">
                      <div className="row">
                        <div className="col-xs-10">
                          <h6>Turnover rate</h6>
                        </div>
                        <div className="col-xs-2">
                          <div className="trend up"><BsFillArrowDownRightCircleFill size={25} fill={"#34D967"}/></div>
                        </div>
                      </div>
                      <div className="number">{TurnoverRate(value.min)}%</div>
                      <div className="percentage-details bottom">
                        <div className="row middle-xs">
                          <div className="col-xs-6 main">{parseFloat((TurnoverRate(value.min)/2).toFixed(1))}%</div>
                          <div className="col-xs-6">
                            <div className="number-caption">-{((TurnoverRate(value.min)/2)/Math.round(TurnoverRate(value.min))*100).toFixed(1)}%</div>
                            <div className="text-caption">Improvement</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

      </div>
      <div className="container footprint">
        <p>* The accuracy of this calculation cannot be guaranteed, is it intended for informational purposes only and should not be relied upon as a basis for any legal or financial decision.</p> 
        <p>* Calculations are based on research from general process of finding the best talent typically involves advertising job postings, recruitment agencies, screening, 
        interviews, hiring and using average numbers for money, and energy to replace workers that could have been retained with a good employee retention strategy.</p>
      </div>
      

      
    </div>
   
  </div>
</section>


<section className="bg-grey">
    <div className="container small">
      <div className="intro-container center">
          <div className="title-container">
            <h2 className="title-2">Challenges</h2>
          </div>
          <div className="desc-container center">
            Identifying and addressing the root causes of employee turnover can be a daunting task for HR professionals, as they navigate the complexities of the modern workforce.

            
          </div>
        </div>
    </div>
  </section>


   

  <section className="white">
    <div className="container row">
      <div className="col-xs-12 col-sm-5">
        <div className="intro-container">

          <div className="title-container">
            <div className="eyebrow">Our solution</div>
            <h2 className="title-2">Harness the potential of social connections</h2>
          </div>
          <div className="desc-container">
            Individuals spend a significant portion of their lives at work and it is often where they could potentially form strong connections and friendships. 
            <br/><br/>The foundation of a great employee retention platform is to create a social workplace community for your organization.          
          </div>

          <div className="row center-xs intro-stats">
            <div className="col-xs-4 col-sm-4">
              {/* <h5>57%</h5> */}
              <h5>
              <CountUp
                      start={0}
                      end={57}
                      duration={1}
                      delay={0}
                      suffix="%"
                      onEnd={() => console.log('Ended')}
                      onStart={() => console.log('Started')}>
                      {({ countUpRef, start }) => (
                        <div>
                          <span ref={countUpRef} />
                          <Waypoint onEnter={()=>start()} />
                        </div>
                      )}
                    </CountUp>
              </h5>
              <p>reported workplace friendships to be more fun and engaging</p>
            </div>
            <div className="col-xs-4 col-sm-4">
              {/* <h5>43%</h5> */}
              <h5>
              <CountUp
                      start={0}
                      end={43}
                      duration={1}
                      delay={0}
                      suffix="%"
                      onEnd={() => console.log('Ended')}
                      onStart={() => console.log('Started')}>
                      {({ countUpRef, start }) => (
                        <div>
                          <span ref={countUpRef} />
                          <Waypoint onEnter={()=>start()} />
                        </div>
                      )}
                    </CountUp>
              </h5>
              <p>higher likelihood of work-praise reporting</p>
            </div>
            <div className="col-xs-4 col-sm-4">
              {/* <h5>12%</h5> */}
              <h5>
              <CountUp
                      start={0}
                      end={12}
                      duration={1}
                      delay={0}
                      suffix="%"
                      onEnd={() => console.log('Ended')}
                      onStart={() => console.log('Started')}>
                      {({ countUpRef, start }) => (
                        <div>
                          <span ref={countUpRef} />
                          <Waypoint onEnter={()=>start()} />
                        </div>
                      )}
                    </CountUp>
              </h5>
              <p>profit increase in companies with coworker friendships</p>
            </div>
          </div>

        </div>
      </div>

        <div className="col-sm-1"></div>
        <div className="col-xs-12 col-sm-6 first-xs last-sm"> 
          <img src={peopleEvent} />
        </div>
    </div>
  </section>

  <section className="bg-white">
    <div className="container small">
      <div className="intro-container center">

          <div className="title-container">
            <img className="title-logo" src={logo}/>
            <h2 className="title-2">Enterprise Social Platform</h2>
          </div>

          <div className="desc-container center">
              {/* MeetApp® is a mobile platform to create a socially connected & inclusive work environment, resulting in increases in employee retention, 
              higher productivity and reduced hiring costs. */}
              MeetApp® is a powerfully immersive employee experience app designed to combat workplace silos and isolated employees.
          </div>
          
        </div>
    </div>


      <Tabs>
        <Tab label="Organization">
          <div className="flexible-card-container">
            <div className="container row middle-xs">
              <div className="col-xs-12 col-sm-4">
                <div className="flexible-card green">
                  <div className="title">Healthier Workforce</div>
                  <div className="flexible-cardtext">Promoting mental health in the workplace is more than just an ethical responsibility, it's a crucial component of a thriving organization</div>
                  <Link onClick={()=>{gaEventTracker('Discover mental health - Navigate to Contact Sales','Button')}} to="/contact-sales">Discover mental health</Link>
                </div>
              </div>
              <div className="col-xs-12 col-sm-8">
                <div className="flexible-card lightyellow">
                  <div className="abstract-text">
                    <div className="title">We bring teams together</div>
                    {/* <div className="flexible-cardtext">Meetapp is an employee experience app designed to help create deeper social interactions at work </div> */}
                    <div className="intro-container">
                      <ul className="checklist">
                        <li><b>Positive Workplace Culture</b>, can lead to a more open exchange of ideas, sparking creativity and innovation</li>
                        <li><b>Eliminate burnout</b> leading to increased productivity, better results and reduced turnover</li>
                        <li><b>Improve communication</b> for a better collaboration and breed healthy challenges</li>
                        <li><b>Increase engagement</b>, friends work better together to solve problems more effectively</li>                                   
                      </ul> 
                    </div>
                    {/* <Link to="/contact-sales">Start earning more</Link> */}
                  </div>
                  <img className="abstract" src={innerGraph}/>
                </div>
              </div>
            </div>            
            <div className="container row">
              <div className="col-xs-12 col-sm-8">
                <div className="flexible-card img">
                    <img src={benefitGraph}/>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <div className="flexible-card white">
                  <div className="title">Profitability at core</div>
                  <div className="flexible-cardtext">Adopt a platform to reduce your employee attrition cost and increase your profitability up to 12%</div>
                  <Link onClick={()=>{gaEventTracker('How to increase profit - Navigate to Contact Sales','Button')}} to="/contact-sales">How to increase profit?</Link>
                </div>
              </div>
            </div>
          </div>
        </Tab>
        <Tab label="Workforce">
          <div className="flexible-card-container">
            <div className="container row middle-xs">
              <div className="col-xs-12 col-sm-8">
                <div className="flexible-card lightyellow">
                  <div className="abstract-text">
                    <div className="title">Culture of friendships at core</div>
                    {/* <div className="flexible-cardtext">Meetapp is an immersive employee experience app designed to encourage team-building and social interactions</div> */}
                    <div className="intro-container">
                      <ul className="checklist">
                        <li><b>Platform</b> to encourage team-building and social interactions</li>
                        <li><b>Work-Life Balance:</b> Happier employee making the workplace a more enjoyable environment</li>
                        <li><b>Emotional Support:</b> Culture of friendship helping employees navigate work-related stress & challenges</li>
                        <li><b>Career Development:</b>Friends can serve as mentors aiding personal and professional development.</li>                                 
                      </ul> 
                    </div>
                    {/* <Link to="/contact-sales">Start earning more</Link> */}
                  </div>
                  <img className="abstract" src={abstract}/>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <div className="flexible-card yellow">
                  <div className="title">Activities</div>
                  <div className="flexible-cardtext">Sport activities in companies boost employee wellness and team unity, promoting a positive and productive work environment.</div>
                  <Link onClick={()=>{gaEventTracker('Employee experience - Navigate to Contact Sales','CTA')}} to="/contact-sales">Employee experience</Link>
                </div>
              </div>
            </div>
            <div className="container row">
              <div className="col-xs-12 col-sm-4">
                <div className="flexible-card blue">
                  <div className="title">Events</div>
                  <div className="flexible-cardtext">Employee-created events foster a sense of ownership, promote creativity and collaboration, contributing to a positive and inclusive workplace culture.</div>
                  <Link onClick={()=>{gaEventTracker('Workplace experience - Navigate to Contact Sales','CTA')}} to="/contact-sales">Workplace experience</Link>
                </div>
              </div>
              <div className="col-xs-12 col-sm-8">
                <div className="flexible-card img">
                    <img src={womangroup}/>
                </div>
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>


</section>
    

  


    <section>
      <div className="container row center-xs">
        <div className="col-xs-12 col-sm-12">
          <div className="intro-container">
            <div className="title-container">
              <div className="eyebrow">Startup Journey</div>
              <h2 className="title-2">We're thrilled with our progress</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12">
            <TimelineObserver
              initialColor="#e5e5e5"
              fillColor="black"
              handleObserve={(setObserver) => (
                <Timeline
                  callback={onCallback}
                  className="timeline"
                  setObserver={setObserver}
                />
              )}
            />
          </div>
          {/* <div className="col-xs-10 col-sm-8">
            <div className="intro-container">
              <div className="desc-container row">
                Driven by a desire to create a positive impact in the world of work, we are focused on enhancing employee's life.
                We developed a platform that provides cutting-edge tools and strategies to foster a thriving workplace environment, 
                which ultimately benefits both employees and businesses.
                <br/><br/>
                To achieve this noble mission, we  embarked on a robust fundraising campaign, 
                seeking the support of like-minded investors and stakeholders who share their passion 
                for cultivating a work culture where employees feel valued and secure.
                <br/><br/>
                The funds raised will be strategically channeled into research, development, 
                and implementation of groundbreaking solutions, ultimately fueling the dreams 
                of countless organizations and their workforce to thrive and prosper together.
                <br/><br/>
                The funds raised will be strategically channeled into research, development, 
                and implementation of groundbreaking solutions, ultimately fueling the dreams 
                of countless organizations and their workforce to thrive and prosper together.
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>


    <section className="bg-grey" style={{marginTop: '100px'}}>
    <div className="container small">
      <div className="intro-container center">
          <div className="title-container">
            <h2 className="title-2">Be a part of the change</h2>
          </div>
          <div className="desc-container whymeet-cta">
            Join us in making a change the industry of work and transform people's lives.
            <div className="row">
              <div className="col col-xs-12 col-sm-6">
                <h5>Investing</h5>
              To propel our growth, we are seeking the support of like-minded investors 
              and stakeholders who share their passion for cultivating a work culture 
              where employees feel valued and secure.
                <Link onClick={()=>{gaEventTracker('Navigate to Contact Sales','Button')}} to="/contact-sales" className="cta">Contact us</Link>
              </div>
              <div className="col col-xs-12 col-sm-6">
                <h5>Careers</h5>
                Dicosver exhilarating careers opportunity where every day brings new challenges 
                and contributions will have a direct impact on your professional growth and the startup success.
                <Link onClick={()=>{gaEventTracker('Navigate to Careers','Button')}} to="/careers" className="cta">Career opportunities</Link>
              </div>
            </div>
          </div>
        </div>
    </div>
  </section>



    

  
   

{/* 
    <section className="white">
      <div className="container row">
        <div className="col-xs-12 col-sm-12">
              <div className="circle-container">
                <div className="circle large">
                  <div>
                    <CountUp
                      start={0}
                      end={12}
                      duration={1}
                      suffix="M"
                      onEnd={() => console.log('Ended')}
                      onStart={() => console.log('Started')}>
                      {({ countUpRef, start }) => (
                        <div>
                          <span ref={countUpRef} />
                          <Waypoint onEnter={()=>start()} />
                        </div>
                      )}
                    </CountUp>
                    <div className="sub">active users</div>
                  </div>
                </div>
                <div className="circle medium">
                  <div>
                    <CountUp
                      start={0}
                      end={12}
                      duration={1}
                      delay={1}
                      suffix="M"
                      onEnd={() => console.log('Ended')}
                      onStart={() => console.log('Started')}>
                      {({ countUpRef, start }) => (
                        <div>
                          <span ref={countUpRef} />
                          <Waypoint onEnter={()=>start()} />
                        </div>
                      )}
                    </CountUp>
                    <div className="sub">active users</div>
                  </div>
                </div>
                <div className="circle small">
                  <div>
                    <CountUp
                      start={0}
                      end={12}
                      duration={1}
                      delay={2}
                      suffix="M"
                      onEnd={() => console.log('Ended')}
                      onStart={() => console.log('Started')}>
                      {({ countUpRef, start }) => (
                        <div>
                          <span ref={countUpRef} />
                          <Waypoint onEnter={()=>start()} />
                        </div>
                      )}
                    </CountUp>
                    <div className="sub">active users</div>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </section> */}




    



  

   

 
    </>
  )
}
 
export default WhyMeetapp;
import React from "react";
import './arrow-btn.scss';
import { Link } from 'react-router-dom';
import {RiArrowRightSLine, RiArrowRightLine} from 'react-icons/ri';
import useAnalyticsEventTracker from './../../helpers/useAnalyticsEventTracker';
 
function ArrowBtn(props) {
    let {ctaText, color, ctaUrl, arrow, gaCategory} = props;
    const gaEventTracker = useAnalyticsEventTracker(gaCategory);
  return (
    <Link to={`/${ctaUrl}`} className={`arrow-button ${color} ${arrow ? 'arrow':''}`} onClick={()=>{gaEventTracker(`${ctaText} clicked navigate to:${ctaUrl}`,'Arrow Button Link')}}>
      <span>{ctaText}</span>
      {arrow &&  
      <>
        <span className="nohover"><RiArrowRightSLine size={20}/></span>
        <span className="hover"><RiArrowRightLine size={20}/></span>
      </>
      }
    </Link>
  )
}
 
export default ArrowBtn;
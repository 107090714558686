import React from "react";
import { Link } from 'react-router-dom';
import Intro from './../components/content/Intro';
import Card from './../components/content/Card';
// import LinkCard from './../components/button/LinkCard';
import ShapeBtn from '../components/button/ShapeBtn';
// import phone from '../assets/phone-profile.png';
import faces from '../assets/faces.png';
import downArrow from '../assets/down-arrow.png';
import imac from '../assets/imac.png';
import android from '../assets/android.png';
import ios from '../assets/ios.png';
import phoneMain from '../assets/main-page-phone.png';
import step1 from '../assets/step1.png';
import step2 from '../assets/step2.png';
import step3 from '../assets/step3.png';
import line from '../assets/line.png';
import event from '../assets/event.png';
import event3 from '../assets/event-add.svg';
import event2 from '../assets/event-notif.svg';
import event1 from '../assets/event-cal.svg';
import security from '../assets/security.svg';
import notifications from '../assets/notifications.svg';
import cloudSearch from '../assets/search-cloud.svg';
import womangroup from '../assets/woman-group.jpg';
import abstract from '../assets/abstract.svg';
import phoneactivities from '../assets/phone-activities.png';
import travel from '../assets/travel-phone.png';
import './../css/platform.scss';
import camping from '../assets/hiking.jpg';
import useAnalyticsScrollEvent from './../helpers/useAnalyticsScrollEvent';

 
function Platform() {
  useAnalyticsScrollEvent('Platform');
  return (
    <>
     
     {/* <section className="naked">
        <img src={camping}/>
        <div className="intro-container image-text light">
          <div className="title-container">
            <h2 className="title-2">Teams together</h2>
          </div>
        </div>
      </section> */}

      <section className="white top-margin">
        <div className="container row middle-xs">
          <div className="col-xs-12 col-sm-7">
            <div className="intro-container">

                <div className="title-container">
                  <div className="eyebrow">Activities</div>
                  <h2 className="title-2">Bring teams together</h2>
                </div>
                <div className="desc-container">
                Meetapp facilitate team-building activities and social events to bring everyone together, foster a positive work culture,
                create an environment where everyone feels valued, supported, and connected, and provide a chance to relax, bond, and have fun outside of the office.    
                </div>

                <div className="pill-container">
                  <div className="pill">Soccer</div>
                  <div className="pill">Movies</div>
                  <div className="pill">Pickleball</div>
                  <div className="pill">Snowboard</div>
                  <div className="pill">Hike</div>
                  <div className="pill">Ping pong</div>
                  <div className="pill">Dodgeball</div>
                  <div className="pill">Volleyball</div>
                  <div className="pill">Board games</div>
                  <div className="pill">Paragliding</div>
                  <div className="pill">Chess</div>
                  <div className="pill">Bar hop</div>
                  <div className="pill">Foodies</div>
                  <div className="pill">Coffee Shop</div>
                  <div className="pill">Tea</div>
                  <div className="pill">Cooking</div>

                  
                </div>
               
               
                
              </div>
            </div>
            {/* <div className="col-sm-1"></div> */}
            <div className="col-xs-10 col-sm-5 first-xs last-sm">
              <img src={phoneactivities}/>
            </div>
        </div>
      </section>

      <section className="white">
        <div className="container row center-xs middle-xs">
         
            
            <div className="col-xs-8 col-sm-4">
              <img src={travel}/>
            </div>
            <div className="col-sm-1"></div>
            <div className="col-xs-12 col-sm-7">
            <div className="intro-container">

                <div className="title-container">
                  <div className="eyebrow">Business Travel</div>
                  <h2 className="title-2">Networking opportunities</h2>
                </div>
                <div className="desc-container">
                  Enhance the employee experience during business travel by fostering connectivity and networking opportunities. <br/><br/>
                  This digital hub acts as a dynamic junction, allowing employees to identify and connect 
                  with colleagues who might be in the same city or attending the same event, thereby enabling spontaneous meet-ups and knowledge exchange.    
                </div>
   
              </div>
            </div>
        </div>
      </section>

      <section className="bg-grey">
        <div className="container small">
          <div className="intro-container center">

              <div className="title-container">
                <div className="process-eyebrow"><span className="bar"></span> <span className="name">Process</span></div>
                <h2 className="title-2">How it works</h2>
              </div>

              <div className="process-container">
                <div className="row center-xs middle-xs">
                  <div className="col-xs-1"><img src={step1}/></div>
                  <div className="col-xs-3"><img src={line}/></div>
                  <div className="col-xs-1"><img src={step2}/></div>
                  <div className="col-xs-3"><img src={line}/></div>
                  <div className="col-xs-1"><img src={step3}/></div>
                </div>
                <div className="row center-xs">
                  <div className="col-xs-4 col-sm-4">
                    <h5>Sign up</h5>
                    <p>Enter your company email to sign up and set up your profile</p>
                  </div>
                  <div className="col-xs-4 col-sm-4">
                    <h5>Match and chat</h5>
                    <p>Scroll through to discover other employee with similar interest and match to chat</p>
                  </div>
                  <div className="col-xs-4 col-sm-4">
                    <h5>Make plans</h5>
                    <p>Once you have a match, meet up to do cool stuff together</p>
                  </div>
                </div>
              </div>
              
            </div>
        </div>
      </section>


      <section className="white top-margin">
        <div className="container row center-xs middle-xs">
          <div className="col-xs-12 col-sm-7">
            <div className="intro-container">

                <div className="title-container">
                  <div className="eyebrow">Culture</div>
                  <h2 className="title-2">Company culture of your dreams</h2>
                </div>
                <div className="desc-container">
                Our platform allows employees to discover aspirations and match with other employees within the organiztion   
                </div>
                <ul className="checklist">
                  <li>Powerful AI algorithm that allows employees to match and meet based on common interests</li>
                  <li>Promote the development of strong meaningful relationships among employees</li>
                  <li>Foster a sense of accountability and recognition among teams</li>
                  <li>Build a sense of camaraderie and belonging among new hires</li>
                  <li>Broaden employees horizons and open up new paths for career growth and development</li>
                </ul>
                
              </div>
            </div>
            <div className="col-sm-1"></div>
            <div className="col-xs-8 col-sm-4 first-xs last-sm">
              <div className="phone-img"><img src={phoneMain}/></div>
            </div>
        </div>
      </section>


     

      <section className="white">
        <div className="container row middle-xs">
       
          <div className="col-xs-12 col-sm-5">
           <img src={event}/>
          </div>
          <div className="col-xs-12 col-sm-1"></div>


          <div className="col-xs-12 col-sm-6">
            <div className="intro-container">

                <div className="title-container">
                  <div className="eyebrow">Event</div>
                  <h2 className="title-2">Elevate your work experience</h2>
                </div>
                <div className="desc-container">
                  Build lasting connections with your colleagues through a variety of engaging social events, fostering a sense of community and connection within the workplace.
                </div>
                <div className="boxed-checklist-container">
                  <div className="boxed-checklist row middle-xs">
                    <div className="col-xs-2"><img src={event1}/></div>
                    <div className="col-xs-10">
                    <h6>Events calendar</h6>
                    <p>Exposing yourself to a variety of events is a great way to expand your horizons and experience new things.</p>
                    </div>
                  </div>
                  <div className="boxed-checklist row middle-xs">
                    <div className="col-xs-2"><img src={event2}/></div>
                    <div className="col-xs-10">
                    <h6>Get notified</h6>
                    <p>Mobile push notifications ensure that everyone stays informed and up-to-date, preventing anyone from missing important updates</p>
                    </div>
                  </div>
                  <div className="boxed-checklist row middle-xs">
                    <div className="col-xs-2"><img src={event3}/></div>
                    <div className="col-xs-10">
                    <h6>Contribute</h6>
                    <p>Create unique and customized events that inspires you. Let your creativity run wild and make your next event one to remember.</p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            
        </div>
      </section>

      


      <section className="white icon-text-section">
        <div className="container row">
          <div className="col-xs-12 col-sm-4">
            <div className="icon-text-container">
              <div className="icon"><img src={security}/></div>
              <div className="title">Platforms</div>
              <div className="content">Whether you're on the go or at your desk, our cross-platform solution adapts to your needs and ensures seamless productivity.</div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-4">
            <div className="icon-text-container">
              <div className="icon"><img src={notifications}/></div>
              <div className="title">Push notifications</div>
              <div className="content">Instantly inform and remind employees of team-building activities and social events to bring everyone together.</div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-4">
            <div className="icon-text-container">
              <div className="icon"><img src={cloudSearch}/></div>
              <div className="title">Advanced search</div>
              <div className="content">Advanced search and filtering can help users find exactly what they are looking for, narrowing down search results based on specific criteria.</div>
            </div>
          </div>
        </div>
      </section>


  

      {/* <section className="bg-white naked">
        <div className="container row end-xs">
          <div className="col-xs-12 col-sm-6">
            <Intro 
                eyebrow={'Company performance'}
                subTitle={'Time and money are scarce resource'}
                content={'Saving cost on turnover, MUI offers a comprehensive suite of UI tools to help you ship new features faster. Start with Material UI, our fully-loaded component library, or bring your own design system to our production-ready components.'}
              />
            </div>
            <div className="col-xs-12 col-sm-6 bg-grey">

              <Intro 
                eyebrow={'Company performance'}
                subTitle={'Time and money are scarce resource'}
                content={'Saving cost on turnover, MUI offers a comprehensive suite of UI tools to help you ship new features faster. Start with Material UI, our fully-loaded component library, or bring your own design system to our production-ready components.'}
              />

            </div>
        </div>
      </section>
      <section className="bg-dark-blue">
        <div className="container row">
          <Intro 
              eyebrow={'Learn more about UI'}
              subTitle={'Move faster with intuitive React UI tools'}
              content={'MUI offers a comprehensive suite of UI tools to help you ship new features faster. Start with Material UI, our fully-loaded component library, or bring your own design system to our production-ready components.'}
            />
        </div>
      </section> */}
     
    </>
  )
}
 
export default Platform;
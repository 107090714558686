import React, {useState, useEffect} from "react";
import './slider.scss';
import useAnalyticsEventTracker from './../../helpers/useAnalyticsEventTracker';

const Slider = ({ min, max, value, step, onChange }) => {
  const gaEventTracker = useAnalyticsEventTracker('Why Meetapp Slider');
    const [minValue, setMinValue] = useState(value ? value.min : min);
    const [maxValue, setMaxValue] = useState(value ? value.max : max);
    const [valueChanged, setValueChanged] = useState(false);
  
    useEffect(() => {
      if (value) {
        setMinValue(value.min);
        setMaxValue(value.max);
      }
    }, [value]);

    const handleEvent = (event) => {
      if (event.type === "mousedown") {
          setValueChanged(true)
         } else {
          setTimeout(()=>{
            setValueChanged(false)
            gaEventTracker(`Employee Count: ${minValue}`,'Slider Value');
          },5000)
         }
     }
   
  
    const handleMinChange = e => {
      e.preventDefault();
      const newMinVal = Math.min(+e.target.value, maxValue - step);
      if (!value) setMinValue(newMinVal);
      onChange({ min: newMinVal, max: maxValue });
    };
  
  
    const minPos = ((minValue - min) / (max - min)) * 100;
    const maxPos = ((maxValue - min) / (max - min)) * 100;
  
    return (
      <div className="sliderwrapper">

        <div className="input-sliderwrapper">
          <input
            className="sliderinput"
            type="range"
            value={minValue}
            min={min}
            max={max}
            step={step}
            onMouseDown={ handleEvent } 
            onMouseUp={ handleEvent }
            onChange={handleMinChange}
          />
        </div>
        
        <div className="slidercontrol-wrapper">
          <div className={`number-box ${valueChanged ? 'active' : null}`} style={{ left: `${minPos}%`, top: '-25px' }}>{(value.min).toLocaleString("en-US")}</div>
          <div className="control pulse" style={{ left: `${minPos}%` }} />
          <div className="rail">
            <div
              className="inner-rail" 
              style={{ left: `${minPos}%`, right: `${100 - maxPos}%` }}
            />
          </div>
        </div>
      </div>
    );
};

export default Slider;
  
import React from "react";
import Intro from '../components/content/Intro';
import ShapeBtn from '../components/button/ShapeBtn';
 
function NotFoundPage(props) {
  return (
    <section className="bg-white center">
        <div className="container">
          <Intro 
            title={"Oops.. we're not ready just yet"}
            content={'Bear with us while we bake this page.'}
          />
           <ShapeBtn ctaText={'Back to home'} ctaUrl={''} arrow={true}/>
        </div>
    </section>
  )
}
 
export default NotFoundPage;
import React from "react";
import './../css/blog.scss';
import { useLocation } from 'react-router-dom';
import { BsCalendar4Week, BsClock } from 'react-icons/bs';
import useAnalyticsScrollEvent from './../helpers/useAnalyticsScrollEvent';


function Blog(props) {
  useAnalyticsScrollEvent('Blog Article');
  const location = useLocation();
  let {badge, headline, content, authorImage, author, date, time, image} = location.state;
  document.title = headline;
  return (
    <>
      <section className="white blog-article">

        <div className="header">
          <div className="container row">
            <div className="col-xs-12 col-sm-6">
              <div className="pill">{badge}</div>
              <div className="title-container">
                <h1>{headline}</h1>
              </div>
              <div className="auth-container">
                <div className="image">{authorImage}</div>
                <div className="content">
                  <div className="author-name">{author}</div>
                  <div className="author-date"><BsCalendar4Week size={20}/> {date}</div>
                  <div className="author-time"><BsClock size={20}/> {time}</div>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-6">
              <div className="main-image"><img src={require(`./../assets/${image}.jpg`)}/></div>
            </div>
            

          </div>
        </div>

        <div className="container row">
          <div className="col-xs-12 col-sm-8">
            <div className="desc-container">
              <div dangerouslySetInnerHTML={{__html:content}}></div>
            </div>
          </div>
        </div> 

      </section>
    </>
  )
}
 
export default Blog;
import ReactGA from "react-ga4";
import { useScrollTracker } from 'react-scroll-tracker';
import useAnalyticsEventTracker from './useAnalyticsEventTracker';

/* 
Category	String. Required. A top level category for these events. E.g. 'User', 'Navigation', 'App Editing', etc.
Action	String. Required. A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
Label	String. Optional. More precise labelling of the related action. E.g. alongside the 'Added a component' action, we could add the name of a component as the label. E.g. 'Survey', 'Heading', 'Button', etc.
*/

const useAnalyticsScrollEvent = (page) => {
  const gaEventTracker = useAnalyticsEventTracker(`Page Scroll Event: ${page}`);
  const scrollTracker = useScrollTracker([25, 50, 75, 100], ({ scrollY }) => {
    gaEventTracker(`Page Scroll: ${page} - ${scrollY}%`,'Scroll')
  });
  return scrollTracker;
}
export default useAnalyticsScrollEvent;
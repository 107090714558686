import React from "react";
import { Link } from 'react-router-dom';
import './list.scss';
import useAnalyticsEventTracker from './../../helpers/useAnalyticsEventTracker';
 
function List(props) {
    let {title, gaCategory} = props;
    let list = props.list;
    const gaEventTracker = useAnalyticsEventTracker(gaCategory);

  return (
    <ul className="list-item col-xs-6 col-sm-3">
        <span>{title}</span>
        {
            list.map((item, i) => {

                // let url = item.toLowerCase().replace(/\s/g, '').replace(/[^\w ]/g, '');
                let url = item.toLowerCase().replace(/[^\w ]/g, '').replace(/\s+/g, '-');
                return <li key={i}><Link to={`/${url}`} className="item" onClick={()=>{gaEventTracker('Navigate to '+item,'List Item Link')}}>{item}</Link></li>    
            })
        }
    </ul> 
  )
}
 
export default List;
import React from "react"
 
function Community(props) {
  return (
    <section>

    
    <div className="container simple-page">
      <h1>Community</h1>

<p>
  Welcome to our ToMeetApp community. We're all about enabling people to sharing activities and have fun. You are encouraged to read these guidelines describing the appropriate behavior conduct between users. Any violation of these policies online or offline, will be sanctioned: we reserve the right to ban violators from our mobile application platform and to delete any data associated with the account.</p>
  
  <p>Please be respectful to others. The goal of this app is to meet people around while traveling or hanging out. We want to allow users to express themselves freely as long as it doesn’t offend others.  Please report any suspicious behavior that infringe violate our policy and we recommend to read our safety page that provide tips and help to meet with other users safely.
</p>
  

<p className="center banner">Find below our community policies:</p>

<h2>No nudity or sexual content allowed</h2>
Sexual/nudity pictures or sexual explicit content in the description/bio and via texting messaging to other user will not be tolerated in our platform. We ask users to remain respectful toward others.

<h2>No harassment</h2>
No harassment in any sort is allowed. No threats, unsolicited sexual content and conduct, stalking, bullying, intimidation will be tolerated. Report any abuse.

<h2>No violence</h2>
physical harm or violence or terrorism content is strictly prohibited. Do not threaten or take any action that will appear to hurt other user in any way. It is also prohibited to promote suicide or self harm.

<h2>No hate speech</h2>
No racism, race, ethnicity, religious affiliation, disability, gender, age, national origin, sexual orientation, gender identity comment or or content that violate others is

<h2>No promotional use</h2>
Soliciting others on our platform is prohibited. promotional, campaign, advertisement, non profit, political campaign, research, contest, event, business use of the application not allowed. 

<h2>Keep your information private</h2>
Do not provide any private information of any sort in your profile or via text message to other user. This includes social security numbers, passports, passwords, financial institutions information, contact information, home/work address, email address, full name, etc.

<h2>No spam or bot</h2>
No external websites or link allowed everything is contained inside the application. No fake user profile allowed.

<h2>No prostitution or trafficking</h2>
promotion of commercial sexual services or human trafficking is not allowed. Any non-consensual sexual acts will be not be tolerated.

<h2>No scamming or phishing</h2>
It is not allowed to attempt to get any other users informations. Any fraudulent or illegal activity will be prohibited.

<h2>No drug or abuse</h2>
No drug content or solicitation is tolerated in our mobile platform. 

<h2>No impersonation</h2> 
Impersonation or misrepresentation is not allowed. Keep the account as real as possible that describe yourself, the goal of this app is to meet people that are a match in order for this to work every individual needs to keep it real.

<h2>No minors</h2>
You must be 18 years of age to use our mobile platform. Minors will not be allowed anywhere.

<h2>No illegal usage</h2>
Do not use our platform in any illegal way, any violators will have their account terminated.

<h2>No copyright or trademark infringement</h2>
Do not copy anything that doesn’t belong to you, this applies to photos or text unless you are explicitly given the right to do so. 



<p>Every individual is only allowed to have one account and it cannot be shared.
If the account hasn’t been used for an extended amount of time (180days) it may be terminated.</p>

<p>Please report any bad behavior or behavior that infringe our listed policies: <a className="link" href="mailto:report@tomeetapp.com">report@tomeetapp.com</a></p>

<p>Refer to the <a className="link" href="/community/safety.html">safety page</a> for some tips about meeting other people.</p>
    </div>
    </section>
  )
}
 
export default Community;
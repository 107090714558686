import React from "react";
import './../css/whymeetapp.scss';
import FAQs from '../data/faqs.json';
import { Link } from 'react-router-dom';
import Lottie from "lottie-react";
import Computer from "../assets/fax.json";
import useAnalyticsEventTracker from '../helpers/useAnalyticsEventTracker';
import './../css/faqs.scss';
import useAnalyticsScrollEvent from './../helpers/useAnalyticsScrollEvent';

function AccordionItem (props){

    const {title, paragraph, id} = props;
    const gaEventTracker = useAnalyticsEventTracker('FAQs');
    useAnalyticsScrollEvent('FAQs');

    const handleClick = (e) => {
      gaEventTracker(`Accordion Item | ${e.target.innerText}`,'Accordion');
      const items = document.querySelectorAll('.accordion-item');
      items.forEach(item => {
        if(item.classList.contains('accordion-item--opened') ){
            item.classList.remove('accordion-item--opened');
        }
        if (item.dataset.id == id){
          item.classList.add('accordion-item--opened');
        }
      });
    }

return (
    <div className={`accordion-item`} onClick={(e) => {handleClick(e)} } data-id={id}>
        <div className='accordion-item__line' data-id={id}>
            <h3 className='accordion-item__title' data-id={id}>
                {title}
            </h3>
            <span className='accordion-item__icon'/>
        </div>
        <div className='accordion-item__inner'>
            <div className='accordion-item__content'>
                <p className='accordion-item__paragraph'>
                    {paragraph}
                </p>
            </div>
        </div>
    </div> 
)

}
 
function Faqs() {
  const gaEventTracker = useAnalyticsEventTracker('FAQs');
  let {fqas} = FAQs;

  return (
    <>
    
  <section className="white no-bottom-margin">
    <div className="container row center center-xs">
      <div className="col-xs-12 col-sm-10">
        <div className="animation"><Lottie animationData={Computer} loop={true} /></div>
        <div className="title-container">
          <h2 className="title-2">Welcome to our FAQ page</h2>
          <p>Our platform is designed to help companies improve employee retention by fostering a sense of community and connection among employees. 
          Here, you'll find answers to some of the most commonly asked questions about our platform and how it can benefit your organization.</p>
        </div>
        <div className="desc-container">
        If you don't find the answer to your question here, please feel free to <Link onClick={()=>{gaEventTracker('Contact Sales','CTA Link');}} to="/contact-sales" className="link">contact us</Link> for further assistance. 
        </div>
      </div>
    </div>
  </section>


    <section className="white no-top-margin">
      <div className="container row">
        <div className="col-xs-12">
          <div className='wrapper'>
            <ul className='accordion-list'>
              {fqas.map((data, key) => {
                return (
                  <li className='accordion-list__item' key={key}>
                    <AccordionItem {...data} />
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>

    </>
  )
}
 
export default Faqs;